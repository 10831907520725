import {Menu, MenuButton, MenuList, MenuItem, Icon, Button} from '@chakra-ui/react';

import {BiDotsVerticalRounded} from 'react-icons/bi';

const ResultsTableEditMenuColumn = ({
  item,
  onDelete,
}: {
  item?: any;
  onDelete?: (item: any) => void;
}) => {
  return (
    <Menu>
      <MenuButton onClick={e => e.stopPropagation()}>
        <Icon as={BiDotsVerticalRounded} fontSize={30} />
      </MenuButton>
      <MenuList>
        <MenuItem
          as={Button}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (onDelete) {
              onDelete(item);
            }
          }}>
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ResultsTableEditMenuColumn;
