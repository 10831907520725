import {baseTheme} from '@chakra-ui/react';

export const THEME_GLOBAL_STYLES = {
  global: {
    'html, body': {
      fontFamily: 'body',
      lineHeight: baseTheme.lineHeights.base,
      color: 'gray.900',
      background: 'gray.50',
    },
    '.mdxeditor-popup-container': {
      zIndex: 9999,
    },
  },
};
