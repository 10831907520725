// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

export default buildFormatter({
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'just now',
  minute: '1 min ago',
  minutes: '%d mins ago',
  hour: '1 hour ago',
  hours: '%d hours ago',
  day: '1 day ago',
  days: '%d days ago',
  month: '1 month ago',
  months: '%d months ago',
  year: '1 year ago',
  years: '%d years ago',
  wordSeparator: ' ',
});
