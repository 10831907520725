import React, {useRef, useCallback} from 'react';
import {Prose} from '@nikolovlazar/chakra-ui-prose';
import {
  MDXEditor,
  MDXEditorMethods,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  tablePlugin,
  toolbarPlugin,
  imagePlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import MDXEditorToolbarContents from './MDXEditorToolbarContents.tsx';
import {v4 as uuidv4} from 'uuid';
import supabaseClient from '../../api/supabaseClient.ts';
import {sharedMarkdownStylesString} from './sharedStyles.ts';

interface MDXEditorComponentProps {
  description: string;
  setDescription: (markdown: string) => void;
  markdownRef: React.RefObject<MDXEditorMethods>;
  placeholder: string;
}

const MDXEditorComponent: React.FC<MDXEditorComponentProps> = ({
  description,
  setDescription,
  markdownRef,
  placeholder,
}) => {
  const editorRef = useRef<HTMLDivElement>(null);

  // This function handles the behavior of the Tab key in the MDXEditor.
  // It checks if the cursor is inside a list item and allows indentation if it is.
  // Otherwise, it prevents the default behavior of the Tab key and inserts spaces.
  // This is necessary because in markdown we can't use the tab character,
  // so we need to insert spaces instead.
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      function isElement(node: Node) {
        return node.nodeType === Node.ELEMENT_NODE;
      }

      function isCursorInsideListItem(node: Node | null): boolean {
        while (node) {
          if (isElement(node) && node.nodeName === 'LI') {
            return true;
          }
          node = node.parentNode;
        }
        return false;
      }

      if (event.key === 'Tab') {
        const editor = markdownRef.current;

        if (editor) {
          const selection = window.getSelection();
          const anchorNode: Node | null = selection?.anchorNode ?? null;

          const isBulletList: boolean = isCursorInsideListItem(anchorNode);

          if (isBulletList) {
            // Allow default behavior for bullet lists (indentation)
            return;
          } else {
            // Prevent the default Tab behavior and insert spaces
            event.preventDefault();
            editor.insertMarkdown('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'); // Insert 8 spaces instead of tab character
          }
        }
      }
    },
    [markdownRef],
  );

  async function imageUploadHandler(image: File): Promise<string> {
    const uniqueId = uuidv4();
    await supabaseClient.storage.from('markdownImages').upload(`${uniqueId}`, image, {
      cacheControl: '3600',
      upsert: false,
    });
    const {data: pathData} = supabaseClient.storage
      .from('markdownImages')
      .getPublicUrl(`${uniqueId}`);
    return pathData.publicUrl;
  }

  return (
    <Prose>
      <style>{sharedMarkdownStylesString}</style>
      <div ref={editorRef} onKeyDown={handleKeyDown}>
        <MDXEditor
          markdown={description}
          ref={markdownRef}
          placeholder={placeholder}
          contentEditableClassName="prose"
          plugins={[
            linkPlugin(),
            linkDialogPlugin(),
            listsPlugin(),
            tablePlugin(),
            headingsPlugin(),
            toolbarPlugin({
              toolbarContents: MDXEditorToolbarContents,
            }),
            imagePlugin({
              imageUploadHandler,
              disableImageSettingsButton: true,
            }),
          ]}
          onChange={(markdown: string) => {
            setDescription(markdown);
          }}
        />
      </div>
    </Prose>
  );
};

export default MDXEditorComponent;
