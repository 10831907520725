import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';

const DashboardPage = (): JSX.Element => {

  return (
    <Box>
      <Heading mb={4}>This is a protected route.</Heading>
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList>
          <Tab>Home</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Text fontSize="xl">
              This is a sample protected route. You can only access this page if you are
              logged in.
            </Text>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default DashboardPage;
