import ReactPaginate from 'react-paginate';
import styles from './paginator.module.css';

const Paginator = ({
  onPageChange,
  pageCount,
  forcePage,
}: {
  onPageChange: ({selected}: {selected: number}) => void;
  pageCount: number;
  forcePage: number;
}) => {
  return (
    <ReactPaginate
      className={styles.container}
      breakLabel="..."
      nextLabel=">"
      onPageChange={onPageChange}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel="<"
      renderOnZeroPageCount={null}
      forcePage={forcePage}
      pageClassName={styles.pageNumber}
      activeClassName={styles.activePage}
      disabledClassName={styles.disabledPrevNextButtons}
    />
  );
};

export default Paginator;
