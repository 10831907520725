// VideoListModal.tsx
import React, {useEffect, useState, ChangeEvent} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  HStack,
  Image,
  Input,
  Badge,
  Checkbox,
} from '@chakra-ui/react';
import useApi from '../../api/hooks/useApi.ts';
import {Video} from '../../types/Video.ts';
import useDebounce from '../../helpers/useDebounce.ts';
import Paginator from '../Paginator/Paginator.tsx';
import {PaginationMeta} from '../../types/PaginationMeta.ts';

interface VideoListModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddVideoSteps: (dayId: string, videos: Video[]) => void;
  dayUuid: string;
  stage: 'pregnant' | 'postpartum' | 'trying_to_conceive' | null;
}

const VideoListModal: React.FC<VideoListModalProps> = ({
  isOpen,
  onClose,
  handleAddVideoSteps,
  dayUuid,
  stage,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const constructUrl = (
    page: number,
    pageSize: number,
    stage: string | null,
    searchQuery: string,
  ) => {
    let url = `/admin/videos?page=${page}&pageSize=${pageSize}`;

    if (stage) {
      url += `&stage=${stage}`;
    }

    if (searchQuery !== '') {
      url += `&title=${searchQuery}`;
    }

    return url;
  };

  const {trigger, data: apiData} = useApi<{videos: Video[]; meta: PaginationMeta}>({
    url: constructUrl(currentPage, 10, stage, searchQuery),
    method: 'GET',
    fetchOnMount: false,
  });

  useEffect(() => {
    if (isOpen) {
      void trigger();
    }
    if (!isOpen) {
      setSelectedVideos([]);
      setSearchQuery('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      void trigger();
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    void trigger();
  }, [currentPage]);

  const [selectedVideos, setSelectedVideos] = useState<Video[]>([]);

  const handleVideoClick = (video: Video) => {
    setSelectedVideos(prevSelected => {
      if (prevSelected.find(v => v.id === video.id)) {
        // If the video is already selected, remove it
        return prevSelected.filter(v => v.id !== video.id);
      } else {
        // If the video is not selected, add it
        return [...prevSelected, video];
      }
    });
  };
  const handleSave = () => {
    if (selectedVideos.length) {
      handleAddVideoSteps(dayUuid, selectedVideos);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Video List</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Search video"
            mb={4}
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <Box>
            {apiData?.videos.map(video => (
              <HStack
                key={video.id}
                mb={4}
                alignItems="center"
                spacing={4}
                onClick={() => handleVideoClick(video)}
                cursor="pointer"
                _hover={{backgroundColor: 'gray.100'}}
                p={2}
                backgroundColor={
                  selectedVideos.some(v => v.id === video.id) ? 'gray.200' : 'transparent'
                }
                borderRadius="8px">
                <Checkbox
                  isChecked={selectedVideos.some(v => v.id === video.id)}
                  onChange={() => handleVideoClick(video)}
                  aria-label={`Select ${video.title}`}
                />
                <Image
                  src={video.thumbnails?.thumbnail ?? video.thumbnailUrl ?? undefined}
                  boxSize="60px"
                  objectFit="cover"
                  borderRadius="12px"
                  alt={video.title}
                />
                <Box>
                  <Text fontSize="lg" fontWeight="medium">
                    {video.title ?? 'No title'}
                  </Text>
                  <Badge colorScheme={video.published ? 'green' : 'gray'}>
                    {video.published ? 'PUBLISHED' : 'UNPUBLISHED'}
                  </Badge>
                </Box>
              </HStack>
            ))}
            {apiData?.meta &&
              (apiData?.meta.hasPreviousPage || apiData?.meta.hasNextPage) && (
                <Paginator
                  onPageChange={({selected}) => {
                    setCurrentPage(selected + 1);
                  }}
                  pageCount={apiData.meta.pageCount}
                  forcePage={currentPage - 1}
                />
              )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            bg="black"
            color="white"
            _hover={{bg: 'gray.700'}}
            onClick={handleSave}
            isDisabled={selectedVideos.length === 0} // Disable the button if no video is selected
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VideoListModal;
