import {format} from 'date-fns';

const ResultsTableDateColumn = ({date}: {date: Date}) => {
  if (typeof date === 'string' || date instanceof Date) {
    return format(new Date(date), 'PPP');
  }
  return null;
};

export default ResultsTableDateColumn;
