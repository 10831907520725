import {extendTheme, withDefaultProps} from '@chakra-ui/react';
import {THEME_COLORS} from './foundations/colors';
import {THEME_FONTS} from './foundations/fonts';
import {THEME_BREAKPOINTS} from './foundations/breakpoints';
import {THEME_SIZES} from './foundations/sizes';
import {THEME_BORDER_RADIUS} from './foundations/borderRadius';
import {THEME_GLOBAL_STYLES} from './foundations/styles';
import {THEME_COMPONENTS} from './foundations/components';
import {withProse} from '@nikolovlazar/chakra-ui-prose';

export const theme = extendTheme(
  {
    colors: THEME_COLORS,
    breakpoints: THEME_BREAKPOINTS,
    styles: THEME_GLOBAL_STYLES,
    components: THEME_COMPONENTS,
    ...THEME_SIZES,
    ...THEME_FONTS,
    ...THEME_BORDER_RADIUS,
  },
  // These are the default props used by all components
  withDefaultProps({
    defaultProps: {
      variant: 'outline',
      size: 'md',
      colorScheme: 'primary',
    },
    components: ['Input', 'NumberInput', 'PinInput'],
  }),
  withDefaultProps({
    defaultProps: {
      variant: 'solid',
      size: 'md',
      colorScheme: 'gray',
    },
    components: ['Button', 'Checkbox'],
  }),
  withProse(),
);
