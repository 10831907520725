import React from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import {v4 as uuidv4} from 'uuid';
import supabaseClient from '../../api/supabaseClient.ts';
import UploadImageModal from '../UploadImageModal/UploadImageModal.tsx';

interface ThumbnailUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  setThumbnailUrl: (uploadedItems: Record<string, string>) => void;
  entityId: string;
  aspect?: number;
  sizes: {width: number; height: number; name: string}[];
}

const ThumbnailUploadModal: React.FC<ThumbnailUploadModalProps> = ({
  isOpen,
  sizes,
  onClose,
  setThumbnailUrl,
  entityId,
  aspect = 1.77,
}) => {
  const updateThumbnail = async (
    blobs: {
      blob: Blob;
      name: string;
    }[],
  ) => {
    if (!blobs.length) {
      return;
    }
    async function uploadSingleBlob(blob: Blob, name: string) {
      const uniqueId = uuidv4();
      await supabaseClient.storage
        .from('thumbnails')
        .upload(`${entityId}/${name}/${uniqueId}`, blob, {
          cacheControl: '3600',
          upsert: false,
        });

      const {data: pathData} = supabaseClient.storage
        .from('thumbnails')
        .getPublicUrl(`${entityId}/${name}/${uniqueId}`);

      return {
        url: pathData.publicUrl,
        name,
      };
    }

    const uploadedBlobs = await Promise.all(
      blobs.map(blobItem => uploadSingleBlob(blobItem.blob, blobItem.name)),
    );

    const mappedBlobs = uploadedBlobs.reduce(
      (acc, item) => {
        acc[item.name] = item.url;
        return acc;
      },
      {} as Record<string, string>,
    );

    setThumbnailUrl(mappedBlobs);
  };

  return (
    <UploadImageModal
      isOpen={isOpen}
      onClose={onClose}
      headerText="Upload Thumbnail"
      aspect={aspect}
      onImageUpload={updateThumbnail}
      sizes={sizes}
    />
  );
};

export default ThumbnailUploadModal;
