import {
  Box,
  Image,
  InputGroup,
  InputLeftElement,
  Input,
  Flex,
  Avatar,
  Grid,
  GridItem,
  Hide,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  AvatarBadge,
} from '@chakra-ui/react';
import natal from '../../assets/images/natal.svg';
import {SearchIcon, BellIcon, HamburgerIcon} from '@chakra-ui/icons';
import {Link as ReactRouterLink} from 'react-router-dom';
import {useGlobalStore} from '../../store/globalStore.ts';
import {PROTECTED_PAGES} from '../router/pages.ts';
import {useQuery} from '@tanstack/react-query';
import {AxiosInstance} from '../../api/_AxiosInstance.ts';
import {User} from '../../types/User.ts';
interface NavbarProps {
  onOpen: () => void;
}
const Navbar = ({onOpen}: NavbarProps): JSX.Element => {
  const logoutSupabaseAuthSession = useGlobalStore(
    state => state.logoutSupabaseAuthSession,
  );
  const notificationsCountQuery = useQuery({
    queryKey: ['notification-count'],
    queryFn: async () => {
      const {data: unreadCount} = await AxiosInstance.get<number>(
        '/notifications/number-of-unread',
      );

      return unreadCount;
    },
  });

  const userDataQuery = useQuery({
    queryKey: ['user-me'],
    queryFn: async () => {
      const {data: user} = await AxiosInstance.get<User>('/users/me');
      return user;
    },
  });

  return (
    <Box bg="black.900" py={5} px={16}>
      <Grid templateColumns="repeat(2, 1fr)">
        <GridItem w="100%" colSpan={1}>
          <Flex alignItems="center">
            <Hide above="md">
              <HamburgerIcon color="white" boxSize={8} mr={6} onClick={onOpen} />
            </Hide>
            <ChakraLink as={ReactRouterLink} to="/programs">
              <Image src={natal} mr={28} />
            </ChakraLink>
            <Hide below="md">
              <InputGroup bg="black.600">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.500" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search"
                  color="gray.400"
                  _placeholder={{color: 'gray.400'}}
                />
              </InputGroup>
            </Hide>
          </Flex>
        </GridItem>
        <GridItem w="100%" colSpan={1}>
          <Flex justify="flex-end" align="center">
            <Box position="relative" display="inline-block" mr={6}>
              <ChakraLink
                position="relative"
                as={ReactRouterLink}
                to={PROTECTED_PAGES.notifications}>
                <BellIcon boxSize={8} color="white" />
              </ChakraLink>
              {notificationsCountQuery.data && notificationsCountQuery.data > 0 && (
                <Box
                  position="absolute"
                  top="4px"
                  right="2px"
                  width="12px"
                  height="12px"
                  borderRadius="50%"
                  bg="red.500"
                  style={{
                    border: '2px black solid',
                  }}
                />
              )}
            </Box>

            <Menu>
              <MenuButton>
                <Avatar size="sm" src={userDataQuery.data?.profilePictureUrl ?? undefined}>
                  <AvatarBadge boxSize="1.25em" bg="green.500" />
                </Avatar>
              </MenuButton>
              <MenuList>
                <MenuItem as={ReactRouterLink} to="/profile">
                  Profile
                </MenuItem>
                <MenuItem onClick={logoutSupabaseAuthSession} closeOnSelect={false}>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Navbar;
