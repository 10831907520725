import {
    Badge
} from '@chakra-ui/react';


const ResultsTableStatusColumn = ({
    status,
    onText,
    offText,
}: {
    status: boolean;
    onText: string;
    offText: string;
}) => {

    return status ? (
        <Badge colorScheme="green">{onText}</Badge>
    ) : (
        <Badge>{offText}</Badge>
    );
};

export default ResultsTableStatusColumn;