import {
  Card,
  CardBody,
  Box,
  Heading,
  Flex,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import {SearchIcon} from '@chakra-ui/icons';
import {Link as RouterLink} from 'react-router-dom';
import {ColumnDef, SortingState, createColumnHelper} from '@tanstack/react-table';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useDebounce from '../../helpers/useDebounce.ts';

import 'react-image-crop/dist/ReactCrop.css';
import useApi from '../../api/hooks/useApi.ts';
import {Program} from '../../types/Program.ts';
import Paginator from '../../components/Paginator/Paginator.tsx';
import ResultsTable from '../../components/ResultsTable/ResultsTable.tsx';
import ResultsTableTitleColumn from '../../components/ResultsTable/ResultsTableTitleColumn.tsx';
import ResultsTableStatusColumn from '../../components/ResultsTable/ResultsTableStatusColumn.tsx';
import ResultsTableStageColumn from '../../components/ResultsTable/ResultsTableStageColumn.tsx';
import ResultsTableDateColumn from '../../components/ResultsTable/ResultsTableDateColumn.tsx';
import ResultsTableEditMenuColumn from '../../components/ResultsTable/ResultsTableEditMenuColumn.tsx';

const columnHelper = createColumnHelper<Program>();

const getColumns = ({onDelete}: {onDelete: (program: Program) => void}) => [
  columnHelper.display({
    header: 'Program',
    cell: info => (
      <ResultsTableTitleColumn
        item={info.row.original}
        linkBase="/programs/edit/"
        isReady={true}
        thumbnailUrl={
          info.row.original.programThumbnails?.thumbnail ??
          info.row.original.thumbnailUrl ??
          undefined
        }
      />
    ),
  }),
  columnHelper.accessor('published', {
    header: 'Published',
    cell: info => (
      <ResultsTableStatusColumn
        status={info.getValue()}
        onText="Published"
        offText="Unpublished"
      />
    ),
  }),
  columnHelper.accessor('stage', {
    header: 'Stage',
    cell: info => {
      const stageValue = info.getValue();
      return <ResultsTableStageColumn stage={stageValue} />;
    },
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('createdAt', {
    header: 'Created Date',
    cell: info => {
      const dateValue = info.getValue();
      return <ResultsTableDateColumn date={dateValue} />;
    },
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.display({
    id: 'edit',
    cell: info => (
      <ResultsTableEditMenuColumn item={info.row.original} onDelete={onDelete} />
    ),
  }),
];

const ProgramsPage = (): ReactElement => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [programData, setProgramData] = React.useState<{
    data: Program[];
    total: number;
    page: number;
    lastPage: number;
  }>({
    data: [],
    total: 0,
    page: 1,
    lastPage: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [programToDeleteId, setProgramToDeleteId] = React.useState<number | null>(null);

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const {isOpen, onOpen, onClose} = useDisclosure();

  const {trigger, data: apiData} = useApi<{
    data: Program[];
    total: number;
    page: number;
    lastPage: number;
  }>({
    url: `/admin/programs?page=${currentPage}&limit=${limit}${searchQuery !== '' ? `&title=${searchQuery}` : ''}`,
    method: 'GET',
    fetchOnMount: true, // Automatically fetch data when the component mounts
  });

  const {trigger: deleteProgramTrigger, isLoading: isDeletingProgram} = useApi<string>({
    url: `/admin/programs/${programToDeleteId}`,
    method: 'DELETE',
    fetchOnMount: false,
  });

  useEffect(() => {
    if (apiData) {
      setProgramData(apiData);
    }
  }, [apiData]);

  useEffect(() => {
    const fetchNewPage = async () => {
      await trigger();
    };
    void fetchNewPage();
  }, [currentPage]);

  useEffect(() => {
    void trigger();
  }, [debouncedSearchQuery]);

  const handleDeleteButtonPressed = useCallback((program: Program) => {
    setProgramToDeleteId(program.id);
    onOpen();
  }, []);

  const columns = useMemo(
    () => getColumns({onDelete: handleDeleteButtonPressed}),
    [handleDeleteButtonPressed],
  );

  const cancelRef = useRef(null);

  const handleCancelDelete = () => {
    setProgramToDeleteId(null);
    onClose();
  };

  const handleDeleteProgram = async () => {
    await deleteProgramTrigger();
    setProgramToDeleteId(null);
    await trigger();
    onClose();
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleCancelDelete}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Program
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can not undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={handleCancelDelete}
                isDisabled={isDeletingProgram}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => void handleDeleteProgram()}
                ml={3}
                isLoading={isDeletingProgram}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Box height="auto">
        <Box maxWidth={'container.xl'}>
          <Flex justifyContent="space-between">
            <Heading mb={4}>Programs</Heading>
            <Button as={RouterLink} to="/programs/new" mb={10} colorScheme="black">
              + New Program
            </Button>
          </Flex>
          <Card maxWidth="container.xl">
            <Box mb={0} px={10} pt={10}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  placeholder="Search by title"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  mb={4}
                  borderRadius="5"
                  borderColor="gray.300"
                  _placeholder={{color: 'gray.400'}}
                  _focus={{borderColor: 'blue.500'}}
                  pl="10"
                  maxWidth="350px"
                />
              </InputGroup>
            </Box>
            <CardBody>
              <ResultsTable
                columns={columns as ColumnDef<any>[]}
                data={programData.data}
                onSortChange={setSorting}
                sorting={sorting}
              />

              {(apiData?.lastPage ?? 1) > 1 && (
                <Paginator
                  onPageChange={({selected}) => {
                    setCurrentPage(selected + 1);
                  }}
                  pageCount={apiData?.lastPage ?? 1}
                  forcePage={currentPage - 1}
                />
              )}
            </CardBody>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default ProgramsPage;
