import {Box, Text} from '@chakra-ui/react';

export default function CoachBadge() {
  return (
    <Box
      width="39px"
      height="18px"
      borderRadius="4px"
      padding="2px 4px 2px 4px"
      bg="#1B1A1A"
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Text fontSize="10px" lineHeight="14px">
        Coach
      </Text>
    </Box>
  );
}
