import {Badge} from '@chakra-ui/react';

const stageStrings: {
  pregnant: {name: string; color: string};
  postpartum: {name: string; color: string};
  trying_to_conceive: {name: string; color: string};
} = {
  pregnant: {
    name: 'Pregnant',
    color: 'teal',
  },
  postpartum: {
    name: 'Postpartum',
    color: 'orange',
  },
  trying_to_conceive: {
    name: 'Trying to conceive',
    color: 'blue',
  },
};

const ResultsTableStageColumn = ({
  stage,
}: {
  stage: 'pregnant' | 'postpartum' | 'trying_to_conceive' | null;
}) => {
  return stage && Object.prototype.hasOwnProperty.call(stageStrings, stage) ? (
    <Badge colorScheme={stageStrings[stage].color}>{stageStrings[stage].name}</Badge>
  ) : null;
};

export default ResultsTableStageColumn;
