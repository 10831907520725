import {create} from 'zustand';
import {persist, createJSONStorage} from 'zustand/middleware';

import {Session} from '@supabase/supabase-js';
import {AxiosInstance} from '../api/_AxiosInstance';
import supabaseClient from '../api/supabaseClient';

// Store types
interface State {
  isLoading: boolean;
  supabaseAuthSession: Session | null;
}

interface Actions {
  setIsLoading: (isLoading: boolean) => void;
  setSupabaseAuthSession: (session: Session | null) => void;
  logoutSupabaseAuthSession: () => void;
  reset: () => void;
}

// Initial state
const initialState: State = {
  isLoading: false,
  supabaseAuthSession: null,
};

// Create store
export const useGlobalStore = create<State & Actions>()(
  persist<State & Actions>(
    (set, _get) => ({
      ...initialState,

      setIsLoading: (newIsLoading: boolean) => {
        set({isLoading: newIsLoading});
      },

      setSupabaseAuthSession: (session: Session | null) => {
        set({supabaseAuthSession: session});
      },

      logoutSupabaseAuthSession: () => {
        supabaseClient.auth.signOut().then(() => {
          delete AxiosInstance.defaults.headers.common.Authorization;
          set({supabaseAuthSession: null});
        });
      },

      reset: () => {
        set(initialState);
      },
    }),
    {
      name: 'global-storage',
      version: 1,
      storage: createJSONStorage(() => localStorage),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      partialize: state =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !['supabaseClient'].includes(key)),
        ),
    },
  ),
);
