const marginBetweenLines = '15px';
const normalFontWeight = 400;
const boldFontWeight = 600;

export const sharedMarkdownStyles = {
  body: {
    'font-family': 'Outfit',
  },
  allText: {
    'font-family': 'Outfit',
    margin: 0,
    'margin-bottom': `${marginBetweenLines}`,
    'font-weight': normalFontWeight,
    'line-height': '1',
    'white-space': 'pre-line',
  },

  h1: {
    'font-size': '40px',
  },
  h2: {
    'font-size': '34px',
  },
  h3: {
    'font-size': '28px',
  },
  h4: {
    'font-size': '22px',
  },
  h5: {
    'font-size': '18px',
  },
  h6: {
    'font-size': '16px',
  },
  p: {
    'font-size': '14px',
  },
  strong: {
    'font-weight': boldFontWeight,
  },
  a: {
    'text-decoration': 'underline',
  },
  th: {
    'text-align': 'center !important',
  },
  thSpan: {
    'font-weight': boldFontWeight,
  },
};

export const sharedMarkdownStylesString = `
        .prose {
          ${Object.entries(sharedMarkdownStyles.body)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
         }
        .prose h1,
        .prose h2,
        .prose h3,
        .prose h4,
        .prose h5,
        .prose h6,
        .prose p{
           ${Object.entries(sharedMarkdownStyles.allText)
             .map(([key, value]) => `${key}: ${value};`)
             .join(' ')}
        }
        .prose h1 {
          ${Object.entries(sharedMarkdownStyles.h1)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
        .prose h2 {
          ${Object.entries(sharedMarkdownStyles.h2)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
        .prose h3 {
          ${Object.entries(sharedMarkdownStyles.h3)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
        .prose h4 {
          ${Object.entries(sharedMarkdownStyles.h4)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
        .prose h5 {
          ${Object.entries(sharedMarkdownStyles.h5)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
         .prose h6 {
          ${Object.entries(sharedMarkdownStyles.h6)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
        .prose p {
          ${Object.entries(sharedMarkdownStyles.p)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
        .prose strong {
          ${Object.entries(sharedMarkdownStyles.strong)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
        .prose a {
          ${Object.entries(sharedMarkdownStyles.a)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
        .prose ol {
          ${Object.entries(sharedMarkdownStyles.allText)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
        .prose ul {
          ${Object.entries(sharedMarkdownStyles.allText)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
        .prose th {
          ${Object.entries(sharedMarkdownStyles.th)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
       .prose th span {
          ${Object.entries(sharedMarkdownStyles.thSpan)
            .map(([key, value]) => `${key}: ${value};`)
            .join(' ')}
        }
        // Add more styles as needed
      `;
