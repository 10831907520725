interface ConfigType {
  APP_TITLE: string;
  ENVIRONMENT: string;
  BACKEND_BASE_URL: string;
  FRONTEND_BASE_URL: string;
  SUPABASE_PUBLIC_URL: string;
  SUPABASE_PUBLIC_ANON_KEY: string;
  BACKEND_WS_URL: string;
  AMITY_API_AUTH_TOKEN: string;
  AMITY_API_KEY: string;
  AMITY_API_URL: string;
  AMITY_APP_CATEGORY_ID: string;
  MOBILE_DEEP_LINK_DOMAIN: string;
}

export const EnvConfig: ConfigType = {
  APP_TITLE: import.meta.env.VITE_APP_TITLE as string,
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT as string,
  BACKEND_BASE_URL: import.meta.env.VITE_BACKEND_BASE_URL as string,
  FRONTEND_BASE_URL: import.meta.env.VITE_FRONTEND_BASE_URL as string,
  SUPABASE_PUBLIC_URL: import.meta.env.VITE_SUPABASE_PUBLIC_URL as string,
  SUPABASE_PUBLIC_ANON_KEY: import.meta.env.VITE_SUPABASE_PUBLIC_ANON_KEY as string,
  BACKEND_WS_URL: import.meta.env.VITE_BACKEND_WS_URL as string,
  AMITY_API_URL: import.meta.env.VITE_AMITY_API_URL as string,
  AMITY_API_AUTH_TOKEN: import.meta.env.VITE_AMITY_API_AUTH_TOKEN as string,
  AMITY_API_KEY: import.meta.env.VITE_AMITY_API_KEY as string,
  AMITY_APP_CATEGORY_ID: import.meta.env.VITE_AMITY_APP_CATEGORY_ID as string,
  MOBILE_DEEP_LINK_DOMAIN: import.meta.env.VITE_MOBILE_DEEP_LINK_DOMAIN as string,
};
