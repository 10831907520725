import {Navigate} from 'react-router-dom';
import {useGlobalStore} from '../../store/globalStore';
import {RouteType} from './routes';
import {AUTH_PAGES, PROTECTED_PAGES} from './pages';
import {FullPageLoader} from '../../components/FullPageLoader';
import {Helmet} from 'react-helmet-async';
import Layout from '../layout/Layout.tsx';

export const RouteComponent = ({
  component: PageComponent,
  type,
  title,
}: RouteType): JSX.Element => {
  const state = useGlobalStore(state => state);

  const isLoading = useGlobalStore(state => state.isLoading);

  if (type === 'auth' && state.supabaseAuthSession) {
    return <Navigate to={PROTECTED_PAGES.programs} replace />;
  }

  if (type === 'protected' && !state.supabaseAuthSession) {
    return <Navigate to={AUTH_PAGES.login} replace />;
  }

  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      {type === 'protected' && isLoading && <FullPageLoader />}
      <Layout type={type}>
        <PageComponent />
      </Layout>
    </>
  );
};
