import { useCallback, useEffect, useState } from "react";
import { AxiosError, AxiosRequestConfig } from "axios";
import { AxiosInstance } from "../_AxiosInstance";

interface UseApiConfig<Data = unknown> extends Omit<AxiosRequestConfig, "data"> {
  data?: Data;
  fetchOnMount?: boolean;
}

interface UseApiOutput<ResponseData, RequestData = unknown> {
  trigger: (data?: RequestData) => Promise<ResponseData>;
  data: ResponseData | null;
  error: AxiosError | null;
  isLoading: boolean;
}

// This hook triggers an Axios request in the following cases:
// - on mount if config.fetchOnMount is true
// - when the trigger function is called

const useApi = <ResponseData, RequestData = unknown>(
  config: UseApiConfig<RequestData>
): UseApiOutput<ResponseData, RequestData> => {
  const [data, setData] = useState<ResponseData | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const trigger = useCallback(
    async (requestData: RequestData = config.data as RequestData) => {
      setIsLoading(true);

      return AxiosInstance.request<ResponseData>({
        ...config,
        data: config.method !== "GET" ? requestData : null,
        params: config.method === "GET" ? requestData : null,
      })
        .then((response) => {
          setData(response.data);
          setError(null);
          return response.data;
        })
        .catch((error: AxiosError) => {
          setData(null);
          setError(error);
          throw error;
        })
        .finally(() => setIsLoading(false));
    },
    [config]
  );

  useEffect(() => {
    if (config.fetchOnMount) trigger();
  }, []);

  return { trigger, data, error, isLoading };
};

export default useApi;
