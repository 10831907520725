export const AUTH_PAGES = {
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
};

export const PROTECTED_PAGES = {
  index: '/',
  dashboard: '/dashboard',
  profile: '/profile',
  changePassword: '/change-password',
  videos: '/videos',
  editVideo: '/videos/edit/:id',
  newProgram: '/programs/new/',
  programs: '/programs',
  editProgram: '/programs/edit/:id',
  notifications: '/notifications',
  users: '/users',
  communities: '/communities',
  communityDetails: '/communities/:id',
  createCommunityPost: '/communities/:id/create-post',
  editSystemPosts: '/communities/posts/:postId',
};
