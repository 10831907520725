import {Flex, HStack, Spinner, Text} from '@chakra-ui/react';

export interface LoadingDataProps {
  text: string;
}

export default function LoadingData(props: Readonly<LoadingDataProps>) {
  const {text} = props;

  return (
    <Flex justifyContent="center" alignItems="center" height="100%">
      <HStack>
        <Text>{text}</Text>
        <Spinner color="blue.500" />
      </HStack>
    </Flex>
  );
}
