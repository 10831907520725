import {Image, Flex, Link as ChakraLink, CircularProgress} from '@chakra-ui/react';

import {Link as ReactRouterLink} from 'react-router-dom';
import {Program} from '../../types/Program';
import {Video} from '../../types/Video';

const ResultsTableTitleColumn = ({
  linkBase,
  isReady,
  item,
  thumbnailUrl,
}: {
  linkBase?: string;
  isReady: boolean;
  item: Program | Video;
  thumbnailUrl: string | undefined;
}) => {
  return (
    <ChakraLink as={ReactRouterLink} to={linkBase ? `${linkBase}${item.id}` : ''}>
      <Flex alignItems="center">
        {isReady ? (
          <Image
            src={thumbnailUrl}
            fallbackSrc="https://via.placeholder.com/150" // Temporary placeholder
            width="75px"
            height="60px"
            objectFit="cover"
            borderRadius={8}
            mr={4}
          />
        ) : (
          <Flex w="75px" height="60px" mr={4} justifyContent="center" alignItems="center">
            {' '}
            <CircularProgress isIndeterminate color="primary.900" size={10} />
          </Flex>
        )}
        {item.title ? item.title : 'No title'}
      </Flex>
    </ChakraLink>
  );
};

export default ResultsTableTitleColumn;
