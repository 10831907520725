import React, {useEffect, useState} from 'react';
import {
  Box,
  Flex,
  Text,
  Hide,
  Link as ChakraLink,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import {Link as ReactRouterLink, useLocation} from 'react-router-dom';
import {PiClipboardText, PiVideoCamera} from 'react-icons/pi';
import {HiOutlineUser, HiOutlineUserGroup} from 'react-icons/hi2';

import {v4 as uuidv4} from 'uuid';
import {CommunityRepository} from '@amityco/ts-sdk';
import {EnvConfig} from '../EnvConfig';
import {IconType} from 'react-icons/lib';

interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const linkHeaderFontSize = 20;
const linksFontSize = 16;

interface MenuItem {
  id: string;
  name: string;
  link: string;
}

interface MenuSectionWithItems {
  header: string;
  icon: IconType;
  items: MenuItem[];
}

interface MenuSectionWithLink {
  header: string;
  icon: IconType;
  items: MenuItem[];
  link?: string;
}

type MenuStructure = Record<string, MenuSectionWithLink>;

const SideMenu = ({isOpen, onClose}: SideMenuProps): React.ReactElement => {
  const location = useLocation();
  const [sideMenuItems, setSideMenuItems] = useState<MenuStructure>({
    programs: {
      header: 'Programs',
      icon: PiClipboardText,
      items: [
        {
          id: uuidv4(),
          name: 'Manage programs',
          link: '/programs',
        },
        {
          id: uuidv4(),
          name: 'New program',
          link: '/programs/new',
        },
      ],
    },
    videos: {
      header: 'Videos',
      icon: PiVideoCamera,
      items: [
        {
          id: uuidv4(),
          name: 'Manage videos',
          link: '/videos',
        },
      ],
    },
    users: {
      header: 'Users',
      icon: HiOutlineUser,
      items: [],
      link: '/users',
    },
    community: {
      header: 'Communities',
      icon: HiOutlineUserGroup,
      items: [],
    },
  });

  useEffect(() => {
    /*
     * Possible params for getCommunities:
     * membership?: 'all' | 'member' | 'notMember'
     * categoryId?: 'categoryId'
     * includeDeleted?: true | false
     * tags?: ['tag1', 'tag2']
     * sortBy?: 'firstCreated' | 'lastCreated'
     */
    const unsubscriber = CommunityRepository.getCommunities(
      {
        categoryId: EnvConfig.AMITY_APP_CATEGORY_ID,
        sortBy: 'displayName',
      },
      ({data, loading, error}) => {
        if (error) {
          // Handle any errors that occur during retrieving data
        }
        if (loading) {
          // Handle the loading state, e.g., show a loading spinner
        }
        if (data) {
          // Process the data
          setSideMenuItems(prev => ({
            ...prev,
            community: {
              ...prev.community,
              items: data.map(community => ({
                id: community.communityId,
                name: community.displayName,
                link: `/communities/${community.communityId}`,
              })),
            },
          }));
        }
      },
    );
    unsubscriber();
  }, []);

  const styles = {
    activeLink: {
      color: '#B57243',
    },
    activeHeader: {
      color: 'black.900',
    },
    inactive: {
      color: 'gray.500',
    },
    versionNumber: {
      color: 'gray',
      position: 'absolute',
      bottom: 50,
    },
  };
  const versionNumber = '1.5.0 (1)';

  return (
    <>
      <Hide below="md">
        <Box
          borderRight="1px"
          borderColor="gray.400"
          pr={5}
          pl={10}
          pt={7}
          minWidth="256px">
          {Object.keys(sideMenuItems).map(key => (
            <Box mb={10} key={sideMenuItems[key].header}>
              <Box mb={3}>
                <Box
                  fontSize={linkHeaderFontSize}
                  sx={
                    sideMenuItems[key].items.some(value =>
                      location.pathname.includes(value.link),
                    )
                      ? styles.activeHeader
                      : styles.inactive
                  }>
                  {sideMenuItems[key].link && (
                    <ChakraLink
                      as={ReactRouterLink}
                      to={sideMenuItems[key].link}
                      fontSize={linksFontSize}>
                      <Flex align="center">
                        <Icon as={sideMenuItems[key].icon} boxSize={5} mr={2} />
                        <Text>{sideMenuItems[key].header}</Text>
                      </Flex>
                    </ChakraLink>
                  )}
                  {!sideMenuItems[key].link && (
                    <Flex align="center">
                      <Icon as={sideMenuItems[key].icon} boxSize={5} mr={2} />
                      <Text>{sideMenuItems[key].header}</Text>
                    </Flex>
                  )}
                </Box>
              </Box>
              {sideMenuItems[key].items.map(item => (
                <Box
                  mb={3}
                  key={item.name}
                  sx={
                    location.pathname === item.link ? styles.activeLink : styles.inactive
                  }>
                  <ChakraLink as={ReactRouterLink} to={item.link} fontSize={linksFontSize}>
                    <Text ml={7}>{item.name}</Text>
                  </ChakraLink>
                </Box>
              ))}
            </Box>
          ))}
          <Box style={styles.versionNumber as React.CSSProperties}>
            Version: {versionNumber}
          </Box>
        </Box>
      </Hide>
      <Hide above="md">
        <Drawer isOpen={isOpen} onClose={onClose} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <Box borderRight="1px" borderColor="gray.400" pr={5} pl={10} pt={7}>
              {Object.keys(sideMenuItems).map(key => (
                <Box mb={10} key={uuidv4()}>
                  <Box mb={3}>
                    <Box
                      fontSize={linkHeaderFontSize}
                      sx={
                        sideMenuItems[key].items.some(value =>
                          location.pathname.includes(value.link),
                        )
                          ? styles.activeHeader
                          : styles.inactive
                      }>
                      <Flex align="center">
                        <Icon as={sideMenuItems[key].icon} boxSize={5} mr={2} />
                        <Text>{sideMenuItems[key].header}</Text>
                      </Flex>
                    </Box>
                  </Box>
                  {sideMenuItems[key].items.map(item => (
                    <Box
                      mb={3}
                      key={item.id}
                      sx={
                        location.pathname === item.link
                          ? styles.activeLink
                          : styles.inactive
                      }>
                      <ChakraLink
                        as={ReactRouterLink}
                        to={item.link}
                        fontSize={linksFontSize}>
                        <Text ml={7}>{item.name}</Text>
                      </ChakraLink>
                    </Box>
                  ))}
                </Box>
              ))}
              <Box style={styles.versionNumber as React.CSSProperties}>
                Version: {versionNumber}
              </Box>
            </Box>
          </DrawerContent>
        </Drawer>
      </Hide>
    </>
  );
};

export default SideMenu;
