import React, {useMemo} from 'react';
import {Button, HStack, useColorMode} from '@chakra-ui/react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {CalendarIcon, TimeIcon} from '@chakra-ui/icons';
import {addHours, addMinutes, format} from 'date-fns';

export interface ChakraDateTimePickerProps {
  selectedDateTime: Date | null;
  onDateTimeChange: (date: Date | null) => void;
}

const ChakraDateTimePicker: React.FC<ChakraDateTimePickerProps> = ({
  selectedDateTime,
  onDateTimeChange,
}) => {
  const {colorMode} = useColorMode();

  const CustomDateInput = React.forwardRef<HTMLInputElement, any>(
    ({value, onClick}, ref) => (
      <Button ref={ref} onClick={onClick} leftIcon={<CalendarIcon />} variant="outline">
        {value || 'Choose date'}
      </Button>
    ),
  );

  const CustomTimeInput = React.forwardRef<HTMLInputElement, any>(
    ({value, onClick, disabled}, ref) => (
      <Button
        ref={ref}
        onClick={onClick}
        leftIcon={<TimeIcon />}
        variant="outline"
        isDisabled={disabled}>
        {value || 'Choose time'}
      </Button>
    ),
  );

  const minDay = useMemo(() => {
    let minDay = new Date(new Date().setHours(0, 0, 0));

    if (
      !selectedDateTime ||
      format(minDay, 'yyyy-MM-dd') === format(selectedDateTime, 'yyyy-MM-dd')
    ) {
      minDay = getMinTodaySchedulePostTime();
    }
    minDay.setSeconds(0, 0);
    return minDay;
  }, [selectedDateTime]);

  const handleDateChange = (date: Date | null) => {
    if (!date) return;

    let newDateTime = selectedDateTime ? new Date(selectedDateTime) : new Date(minDay);
    newDateTime.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());

    if (
      selectedDateTime &&
      format(new Date(), 'yyyy-MM-dd') === format(newDateTime, 'yyyy-MM-dd')
    ) {
      newDateTime = getMinTodaySchedulePostTime();
    }
    onDateTimeChange(newDateTime);
  };

  const handleTimeChange = (time: Date | null) => {
    if (!time) return;
    const newDateTime = selectedDateTime ? new Date(selectedDateTime) : new Date();
    newDateTime.setHours(time.getHours(), time.getMinutes());
    newDateTime.setSeconds(0, 0);
    onDateTimeChange(newDateTime);
  };

  return (
    <HStack spacing={4}>
      <DatePicker
        selected={selectedDateTime}
        onChange={handleDateChange}
        customInput={<CustomDateInput />}
        popperClassName={colorMode === 'dark' ? 'dark-theme' : ''}
        minDate={minDay}
      />
      <DatePicker
        selected={selectedDateTime}
        onChange={handleTimeChange}
        disabled={!selectedDateTime}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="h:mm aa"
        minTime={minDay}
        maxTime={new Date(new Date().setHours(23, 59))}
        customInput={<CustomTimeInput />}
        popperClassName={colorMode === 'dark' ? 'dark-theme' : ''}
      />
    </HStack>
  );
};

function getMinTodaySchedulePostTime() {
  let minDay;

  if (60 - new Date().getMinutes() <= 30) {
    minDay = addHours(new Date(), 1);
  } else {
    minDay = addMinutes(new Date(), 60 - new Date().getMinutes());
  }
  minDay.setSeconds(0, 0);
  return minDay;
}

export default ChakraDateTimePicker;
