import {
  Card,
  CardBody,
  Box,
  Heading,
  Input,
  CircularProgress,
  Flex,
  Button,
  Grid,
  GridItem,
  RadioGroup,
  Stack,
  Radio,
  Select,
  Image,
  useDisclosure,
  Text,
} from '@chakra-ui/react';

import React, {ReactElement, useEffect, useRef} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import useApi from '../../../api/hooks/useApi.ts';
import {Video} from '../../../types/Video.ts';
import {useParams} from 'react-router-dom';
import {MDXEditorMethods} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import MuxPlayer from '@mux/mux-player-react';
import MDXEditorComponent from '../../../components/MDXEditorComponent/MDXEditorComponent.tsx';
import ThumbnailUploadModal from '../../../components/ThumbnailUploadModal/ThumbnailUploadModal.tsx';
import './muxPlayerStyles.css';
const EditVideoPage = (): ReactElement => {
  const {id} = useParams();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const {trigger: updateVideo, isLoading: isUpdating} = useApi<Video>({
    url: `/admin/videos/${id}`,
    method: 'PUT',
    fetchOnMount: false, // Automatically fetch data when the component mounts
  });

  const {data: video, isLoading} = useApi<Video>({
    url: `/admin/videos/${id}`,
    method: 'GET',
    fetchOnMount: true, // Automatically fetch data when the component mounts
  });

  const markdownRef = useRef<MDXEditorMethods>(null);
  const [isChanged, setIsChanged] = React.useState<boolean>(false);
  const [initialVideoData, setInitialVideoData] = React.useState<Video | null>(null);
  const [title, setTitle] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [published, setPublished] = React.useState<boolean>(false);
  const [stage, setStage] = React.useState<
    'pregnant' | 'postpartum' | 'trying_to_conceive' | null
  >(null);
  const [thumbnailUrl, setThumbnailUrl] = React.useState<string | null>(null);
  const [thumbnails, setThumbnails] = React.useState<Record<string, string> | null>(null);

  useEffect(() => {
    if (video) {
      setInitialVideoData(video);
      if (video.title) {
        setTitle(video.title);
      }
      if (video.description) {
        setDescription(video.description);
        markdownRef.current?.setMarkdown(video.description);
      }
      setPublished(video.published);
      if (video.stage) {
        setStage(video.stage);
      }
      if (video.thumbnailUrl) {
        setThumbnailUrl(video.thumbnailUrl);
      }
      if (video.thumbnails) {
        setThumbnails(video.thumbnails);
      }
    }
  }, [video]);

  useEffect(() => {
    if (initialVideoData) {
      checkDataChanged();
    }
  }, [title, description, published, stage, thumbnails]);

  const checkDataChanged = () => {
    if (
      JSON.stringify(initialVideoData) ===
      JSON.stringify({
        ...initialVideoData,
        title,
        description,
        published,
        stage,
        thumbnails,
      })
    ) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  };

  const saveVideo = () => {
    void (async () => {
      const updatedVideo = await updateVideo({
        title,
        description,
        published,
        stage,
        thumbnailUrl,
        thumbnails,
      });
      window.toast({
        description: 'Video updated successfully',
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 3000,
      });
      setInitialVideoData(updatedVideo);
      setIsChanged(false);
    })();
  };

  return (
    <Box minHeight="100%" height="100%">
      <ThumbnailUploadModal
        isOpen={isOpen}
        onClose={onClose}
        setThumbnailUrl={items => setThumbnails(items)}
        entityId={id ?? ''}
        aspect={1}
        sizes={[
          {width: 400, height: 400, name: 'thumbnail'},
          {width: 800, height: 800, name: 'videoThumbnail'},
        ]}
      />
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" height="100%">
          <CircularProgress isIndeterminate color="primary.900" size={24} />
        </Flex>
      ) : (
        <Box>
          <Flex alignItems="center" justifyContent="space-between" maxWidth="container.xl">
            <Heading mb={4}>
              {initialVideoData?.title ? initialVideoData.title : 'New video'}
            </Heading>
            <Button
              colorScheme="black"
              isDisabled={!isChanged || isLoading}
              onClick={saveVideo}
              isLoading={isUpdating}
              mb={10}>
              Save
            </Button>
          </Flex>
          <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(5, 1fr)"
            gap={5}
            maxWidth="container.xl">
            <GridItem colSpan={3}>
              <Card mb={5}>
                <CardBody p={10}>
                  <Heading size="sm" pb={3}>
                    Video details
                  </Heading>
                  <Input
                    placeholder="Video title"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    mb={5}
                  />
                  <Box border="1px solid" borderColor="gray.200" borderRadius="md" mb={10}>
                    <MDXEditorComponent
                      description={description}
                      setDescription={setDescription}
                      markdownRef={markdownRef}
                      placeholder="Video description"
                    />
                  </Box>
                  <Heading size="sm" pb={3}>
                    Stage
                  </Heading>
                  <Select
                    placeholder="Select stage"
                    value={stage ?? undefined}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      if (
                        event.target.value === 'pregnant' ||
                        event.target.value === 'postpartum' ||
                        event.target.value === 'trying_to_conceive'
                      ) {
                        setStage(event.target.value);
                      }
                    }}>
                    <option value="pregnant">Pregnant</option>
                    <option value="postpartum">Postpartum</option>
                    <option value="trying_to_conceive">Trying to conceive</option>
                  </Select>
                </CardBody>
              </Card>
              <Card>
                <CardBody p={10}>
                  <Heading size="sm" pb={3}>
                    Thumbnail
                  </Heading>
                  {!thumbnails?.thumbnail && thumbnailUrl && (
                    <Text mt={2} textAlign="center" mb={4}>
                      Thumbnail has not been reuploaded
                    </Text>
                  )}
                  <Flex justifyContent="center" alignItems="center">
                    {thumbnails && (
                      <Image
                        height="400px"
                        width="400px"
                        src={thumbnails?.thumbnail ?? thumbnailUrl}
                      />
                    )}
                    <Button
                      onClick={onOpen}
                      position="absolute"
                      backgroundColor="#80808030">
                      Change thumbnail
                    </Button>
                  </Flex>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={2}>
              <Card mb={5}>
                <CardBody p={10}>
                  <Heading size="sm" pb={3}>
                    Video
                  </Heading>
                  <MuxPlayer
                    playbackId={video?.muxAsset.playbackId}
                    tokens={{
                      playback: video?.playbackToken,
                    }}
                    poster={thumbnails?.videoThumbnail ?? thumbnailUrl ?? ''}
                    style={{backgroundColor: 'red'}}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody p={10}>
                  <Heading size="sm" pb={3}>
                    Video status
                  </Heading>
                  <RadioGroup
                    onChange={val => {
                      switch (val) {
                        case '0':
                          setPublished(false);
                          break;
                        case '1':
                          setPublished(true);
                          break;
                      }
                    }}
                    value={published ? '1' : '0'}>
                    <Stack direction="column">
                      <Radio value={'0'}>Unpublished</Radio>
                      <Radio value={'1'}>Published</Radio>
                    </Stack>
                  </RadioGroup>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default EditVideoPage;
