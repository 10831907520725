import {CircularProgress, Text} from '@chakra-ui/react';

export const FullPageLoader = () => {
  return (
    <div
      style={{
        backdropFilter: 'blur(4px)',
        WebkitBackdropFilter: 'blur(4px)',
        height: '100vh',
        width: '100vw',
        zIndex: 1000,
        background: 'rgba(0, 0, 0, 0.25)',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Text mr={4}>Loading...</Text>
      <CircularProgress isIndeterminate color="blue.500" size={12} />
    </div>
  );
};
