import React from 'react';
import {Box, Flex, useDisclosure} from '@chakra-ui/react';
import {RouteType} from '../router/routes';
import Navbar from './Navbar.tsx';
import SideMenu from './SideMenu.tsx';

interface LayoutProps {
  type: RouteType['type'];
  children: React.ReactElement;
}
const Layout = ({type, children}: LayoutProps): React.ReactElement => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  return (
    <Box h="auto" minHeight="100%">
      {type !== 'auth' ? (
        <Flex h="auto" minHeight="100vh" flexDirection="column">
          <Navbar onOpen={onOpen} />
          <Flex flex={1}>
            <SideMenu isOpen={isOpen} onClose={onClose} />
            <Box px={16} py={16} w="100%">
              {children}
            </Box>
          </Flex>
        </Flex>
      ) : (
        <Box>{children}</Box>
      )}
    </Box>
  );
};

export default Layout;
