import React from 'react';
import {
  Box,
  Flex,
  FormControl,
  Tag,
  TagLabel,
  TagCloseButton,
  Heading,
} from '@chakra-ui/react';
import CreatableSelect from 'react-select/creatable';
import {MultiValue} from 'react-select';

const equipmentOptions = [
  {value: 'long resistance band', label: 'long resistance band'},
  {value: 'hip circle', label: 'hip circle'},
  {value: 'pilates ball', label: 'pilates ball'},
  {value: 'yoga ball', label: 'yoga ball'},
  {value: 'foam roller', label: 'foam roller'},
  {value: 'small hard ball', label: 'small hard ball'},
  {value: 'blanket', label: 'blanket'},
  {value: 'pillow', label: 'pillow'},
  {value: 'towel', label: 'towel'},
  {value: 'dumbbells', label: 'dumbbells'},
  {value: 'dumbbell', label: 'dumbbell'},
  {value: 'yoga block', label: 'yoga block'},
  {value: 'bench', label: 'bench'},
  {value: 'mat', label: 'mat'},
  {value: 'bench or chair', label: 'bench or chair'},
  {value: 'treadmill', label: 'treadmill'},
  {value: 'balloon', label: 'balloon'},
  {value: 'barbell', label: 'barbell'},
];

export interface EquipmentOption {
  value: string;
  label: string;
}

interface EquipmentListProps {
  selectedEquipment: EquipmentOption[];
  setSelectedEquipment: React.Dispatch<React.SetStateAction<EquipmentOption[]>>;
}

const EquipmentList: React.FC<EquipmentListProps> = ({
  selectedEquipment,
  setSelectedEquipment,
}) => {
  const handleSelectChange = (selectedOptions: MultiValue<EquipmentOption>): void => {
    setSelectedEquipment((selectedOptions as EquipmentOption[]) || []);
  };

  const handleRemoveEquipment = (equipment: string): void => {
    setSelectedEquipment(selectedEquipment.filter(item => item.value !== equipment));
  };

  return (
    <Box>
      <FormControl>
        <Heading size="sm" pb={3} mt={6}>
          Filters
        </Heading>
        <CreatableSelect
          isMulti
          value={selectedEquipment}
          onChange={handleSelectChange}
          options={equipmentOptions}
          placeholder="Equipment"
          controlShouldRenderValue={false}
        />
      </FormControl>
      <Flex mt={2} flexWrap="wrap">
        {selectedEquipment.map(equipment => (
          <Tag
            size="md"
            key={equipment.value}
            borderRadius="5"
            variant="solid"
            colorScheme="gray"
            color="gray.800"
            backgroundColor="#E2E8F0" // Adjust the background color to match the desired look
            px={2} // Adjust horizontal padding
            py={1} // Adjust vertical padding
            m={1}>
            <TagLabel>{equipment.label}</TagLabel>
            <TagCloseButton onClick={() => handleRemoveEquipment(equipment.value)} />
          </Tag>
        ))}
      </Flex>
    </Box>
  );
};

export default EquipmentList;
