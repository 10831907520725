import {
  UndoRedo,
  BoldItalicUnderlineToggles,
  CreateLink,
  CodeToggle,
  InsertTable,
  ListsToggle,
  BlockTypeSelect,
  InsertImage,
} from '@mdxeditor/editor';

const MDXEditorToolbarContents = () => {
  return (
    <>
      <UndoRedo />
      <BoldItalicUnderlineToggles options={['Bold', 'Underline']} />
      <CreateLink />
      <CodeToggle />
      <InsertTable />
      <ListsToggle options={['number', 'bullet']} />
      <BlockTypeSelect />
      <InsertImage />
    </>
  );
};

export default MDXEditorToolbarContents;
