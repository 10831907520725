import { Card, CardBody, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { useRouteError } from "react-router";
import { Link } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

const ErrorWrapperPage = (): JSX.Element => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const error: any = useRouteError();

  return (
    <Container minH="100%">
      <Flex justifyContent="center" alignItems="center" width="100%" minH="100vh">
        <Card width={640}>
          <CardBody>
            <Heading mt={8} size="lg" textAlign="center">
              Ooops!
            </Heading>
            <Text fontSize="xl" align="center" mt={2} color="gray">
              An unexpected error has occurred.
            </Text>

            <Flex justifyContent="center">
              <Text fontSize="xl" align="center" mt={2} color="red.500">
                {/* eslint-disable-next-line  @typescript-eslint/no-unsafe-member-access */}
                {error?.status ? `(${error?.status}) ` : ""}
              </Text>
              <Text
                fontSize="xl"
                align="center"
                mt={2}
                ml={2}
                color="gray.700"
                fontWeight="medium"
              >
                {/* eslint-disable-next-line  @typescript-eslint/no-unsafe-member-access */}
                {error?.statusText || error?.message}
              </Text>
            </Flex>

            <Flex justifyContent="center" mt={8}>
              <ChakraLink
                as={Link}
                to="/"
                color="blue.500"
                fontWeight="medium"
                fontSize="lg"
                _hover={{ color: "gray.900" }}
              >
                Go home.
              </ChakraLink>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </Container>
  );
};

export default ErrorWrapperPage;
