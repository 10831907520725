import ConfirmPopup from './ConfirmPopup';

export interface DeleteConfirmPopupProps {
  onDelete: () => void;
  onCancel: () => void;
  cancelRef: React.LegacyRef<HTMLButtonElement>;
  isOpen: boolean;
  loading: boolean;
  headerText: string;
}

export default function DeleteConfirmPopup(props: DeleteConfirmPopupProps) {
  const {onDelete, onCancel, headerText, cancelRef, isOpen, loading} = props;

  return (
    <ConfirmPopup
      onDelete={onDelete}
      onCancel={onCancel}
      cancelRef={cancelRef}
      isOpen={isOpen}
      loading={loading}
      headerText={headerText}
      dialogDescriptionText="Are you sure? You can not undo this action afterwards."
      confirmButtonText="Delete"
      confirmButtonColor="red"
    />
  );
}
