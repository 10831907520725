import {Avatar, Input, InputGroup, InputRightElement, Button} from '@chakra-ui/react';

export interface NotificationSendCommentInputProps {
  comment: string;
  setComment: (value: string) => void;
  loadingCreatingReply: boolean;
  handlePostClick: () => void;
}

export default function NotificationSendCommentInput(
  props: NotificationSendCommentInputProps,
) {
  const {comment, setComment, loadingCreatingReply, handlePostClick} = props;
  return (
    <>
      <Avatar size="sm" src={'avatarUrl'} />
      <InputGroup flex="1">
        <Input
          placeholder="Add a comment..."
          borderRadius="full"
          bg="#FBF4EE"
          borderColor="#B5724366"
          value={comment}
          onChange={e => setComment(e.target.value ?? '')}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handlePostClick();
            }
          }}
          disabled={loadingCreatingReply}
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            size="sm"
            bg="transparent"
            color="blue.500"
            _hover={{
              bg: 'transparent',
            }}
            isDisabled={!comment || loadingCreatingReply}
            isLoading={loadingCreatingReply}
            onClick={handlePostClick}>
            Post
          </Button>
        </InputRightElement>
      </InputGroup>
    </>
  );
}
