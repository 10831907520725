import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import PostInput from './PostInput';
import {AmitySdkPost} from '../../types/amity/AmitySdkPost';
import {useEffect, useState} from 'react';
import {useMutation} from '@tanstack/react-query';
import {AxiosInstance} from '../../api/_AxiosInstance';

export interface EditPostModalProps {
  post: AmitySdkPost;
  isOpen: boolean;
  onUpdatePost: (post: Partial<AmitySdkPost>) => void;
  onClose: () => void;
}

export default function EditPostModal(props: EditPostModalProps) {
  const {post, isOpen, onClose, onUpdatePost} = props;

  const [postText, setPostText] = useState(post.data.text);
  const [postImages, setPostImages] = useState<string[]>(post.metadata?.postImages ?? []);
  const [showImagesOnTop, setShowImagesOnTop] = useState(!!post.metadata?.showImagesOnTop);

  useEffect(() => {
    return () => {
      setPostText(post.data.text);
      setPostImages(post.metadata?.postImages ?? []);
      setShowImagesOnTop(post.metadata?.showImagesOnTop ?? false);
    };
  }, [isOpen]);

  function removePostImage(url: string) {
    setPostImages(prev => prev.filter(u => u !== url));
  }

  const handleImageUpload = (url: string) => {
    setPostImages(prev => [...prev, url]);
  };

  const editPostMutation = useMutation({
    mutationFn: async () => {
      const updatedPost = {
        ...post,
        data: {
          text: postText,
        },
        metadata: {postImages, showImagesOnTop},
      };

      await AxiosInstance.put(`/admin/communities/amity-post/${post.postId}`, updatedPost);

      onUpdatePost({
        postId: post.postId,
        data: {
          text: postText,
        },
        metadata: {postImages, showImagesOnTop},
      });
      onClose();
    },
    onError: error => {
      console.error('Error editing post:', error);
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit post</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormControl display="flex" alignItems="center" justifyContent="space-between">
            <FormLabel htmlFor="image-placement-switch" mb={6}>
              Show images on top of post
            </FormLabel>
            <Switch
              id="image-placement-switch"
              colorScheme="black"
              isChecked={showImagesOnTop}
              onChange={e => setShowImagesOnTop(e.target.checked)}
            />
          </FormControl>
          <PostInput
            isEditing
            postText={postText}
            postImageUrls={postImages}
            onChangePostText={text => setPostText(text)}
            onImageUpload={handleImageUpload}
            onRemovePostImage={removePostImage}
          />
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Cancel
          </Button>
          <Button
            colorScheme="black"
            onClick={() => editPostMutation.mutate()}
            isLoading={editPostMutation.isPending}>
            Edit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
