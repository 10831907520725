import {useEffect, DependencyList} from 'react';

export function useDebounceEffect(fn: () => void, waitTime: number, deps?: DependencyList) {
  useEffect(() => {
    const t = setTimeout(() => {
      if (deps) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fn(...Array.from(deps));
      } else {
        fn();
      }
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}
