import {Menu, MenuButton, MenuList, MenuItem, Icon, Button} from '@chakra-ui/react';

import {BiDotsVerticalRounded} from 'react-icons/bi';

const ResultsTableActionsColumn = ({
  menuItems = [],
}: {
  menuItems: {
    label: string;
    onClick: () => void;
  }[];
}) => {
  return (
    <Menu>
      <MenuButton onClick={e => e.stopPropagation()}>
        <Icon as={BiDotsVerticalRounded} fontSize={30} />
      </MenuButton>
      <MenuList>
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={index}
            as={Button}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (menuItem.onClick) {
                menuItem.onClick();
              }
            }}>
            {menuItem.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default ResultsTableActionsColumn;
