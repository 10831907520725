import {
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link as ChakraLink,
  Stack,
  Text,
  Center,
  useToast,
} from '@chakra-ui/react';
import {useState} from 'react';
import {Link} from 'react-router-dom';

import {EnvConfig} from '../../../base/EnvConfig';

import {AUTH_PAGES} from '../../../base/router/pages';
import supabaseClient from '../../../api/supabaseClient';

const ForgotPasswordPage = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const resetPassword = () => {
    setIsLoading(true);
    const changePasswordUrl = EnvConfig.FRONTEND_BASE_URL + '/change-password';
    supabaseClient.auth
      .resetPasswordForEmail(email, {redirectTo: changePasswordUrl})
      .then(res => {
        if (res.error) {
          toast({
            description: res.error.message || 'An error occurred. Please try again.',
            status: 'error',
            title: 'Error',
          });
        } else {
          setResetSent(true);
        }
        setIsLoading(false);
      });
  };

  return (
    <Container minH="100%">
      <Flex justifyContent="center" alignItems="center" width="100%" minH="100vh">
        <Card width={480}>
          <CardBody>
            <Text fontSize="md" align="center" mt={2} color="gray">
              Reset your password.
            </Text>
            <form>
              <Stack spacing={4} mt={8}>
                <FormControl variant="floating" hidden={resetSent}>
                  <Input
                    placeholder="" // leave empty for floating label
                    aria-label="Email"
                    name="email"
                    type="email"
                    autoComplete="password"
                    required
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <FormLabel>Email</FormLabel>
                </FormControl>

                <Button
                  my={4}
                  isDisabled={!email}
                  onClick={resetPassword}
                  hidden={resetSent}
                  colorScheme="green"
                  isLoading={isLoading}>
                  Reset Password
                </Button>
                <Text hidden={!resetSent} color="green.500" align={'center'}>
                  Reset email sent. Please check your inbox.
                </Text>
                <Center flexWrap="wrap">
                  <Text fontSize="sm" color="gray.500" me={1}>
                    Remember your password?
                  </Text>
                  <ChakraLink
                    as={Link}
                    to={AUTH_PAGES.login}
                    color="gray.600"
                    fontWeight="medium"
                    fontSize="sm"
                    _hover={{color: 'gray.900'}}>
                    Login
                  </ChakraLink>
                </Center>
              </Stack>
            </form>
          </CardBody>
        </Card>
      </Flex>
    </Container>
  );
};

export default ForgotPasswordPage;
