import {CommunityRepository, PostRepository} from '@amityco/ts-sdk';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Text,
  Switch,
} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@tanstack/react-query';
import {useUpdateEffect} from 'react-use';
import {format} from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

import {AxiosInstance} from '../../api/_AxiosInstance';
import PostInput from './PostInput';
import {SystemPost} from '../../types/Communities/SystemPost';

import ChakraDateTimePicker from '../../components/Common/ChakraDateTimePicker';

export default function CommunityPostPage() {
  const [community, setCommunity] = useState<Amity.Community | null>(null);
  const [loadingCommunity, setLoadingCommunity] = useState(false);
  const [CommunityError, setCommunityError] = useState<string | null>(null);

  const [isScheduled, setIsScheduled] = useState(false);
  const [showImagesOnTop, setShowImagesOnTop] = useState(false);

  const [postText, setPostText] = useState('');
  const [postImages, setPostImages] = useState<string[]>([]);

  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);

  function removePostImage(url: string) {
    setPostImages(prev => prev.filter(u => u !== url));
  }

  const handleImageUpload = (url: string) => {
    setPostImages(prev => [...prev, url]);
  };

  const {id, postId} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    /*
     * Possible params for getCommunities:
     * membership?: 'all' | 'member' | 'notMember'
     * categoryId?: 'categoryId'
     * includeDeleted?: true | false
     * tags?: ['tag1', 'tag2']
     * sortBy?: 'firstCreated' | 'lastCreated'
     */
    const unsubscriber = CommunityRepository.getCommunity(
      id,
      ({data: community, loading, error}) => {
        if (error) {
          setCommunityError('There was an error getting the Community.');
          setLoadingCommunity(false);
          // Handle any errors that occur during retrieving data
        }
        if (loading) {
          setLoadingCommunity(true);
          // Handle the loading state, e.g., show a loading spinner
        }
        if (community) {
          // Process the data
          setCommunity(community);
          setLoadingCommunity(false);
          setCommunityError(null);
        }
      },
    );
    unsubscriber();
  }, [id]);

  useUpdateEffect(() => {
    if (!isScheduled) {
      setSelectedDateTime(null);
    }
  }, [isScheduled]);

  const getPostDetailsQuery = useQuery({
    queryKey: ['getPostDetails', postId],
    queryFn: async () => {
      const {data: systemPost} = await AxiosInstance.get<SystemPost>(
        `/admin/communities/posts/${postId}`,
      );
      setPostText(systemPost.text);
      setPostImages(systemPost.images ?? []);
      setIsScheduled(!!systemPost.scheduledAt);
      setShowImagesOnTop(systemPost.showImagesOnTop);

      if (systemPost.scheduledAt) {
        const scheduledDate = new Date(systemPost.scheduledAt);
        setSelectedDateTime(scheduledDate);
      }
      return systemPost;
    },
    enabled: !!postId,
  });

  const createOrUpdatePostMutation = useMutation({
    mutationFn: async (isDraft: boolean) => {
      const communityId = getPostDetailsQuery.data?.communityId ?? community?.communityId;

      // Post not ready to be published
      if (isDraft || isScheduled) {
        // Update post if it exists
        if (getPostDetailsQuery.data) {
          await AxiosInstance.put(`/admin/communities/posts/${postId}`, {
            text: postText,
            images: postImages,
            isDraft,
            showImagesOnTop,
            scheduledAt: isScheduled ? selectedDateTime?.toISOString() : null,
          });
          if (isDraft) {
            window.toast({
              description: 'Draft has been updated',
              status: 'success',
              position: 'top',
              isClosable: true,
              duration: 5000,
            });
          }
        }
        // create new system post
        else {
          await AxiosInstance.post('/admin/communities/posts', {
            text: postText,
            images: postImages,
            isDraft,
            showImagesOnTop,
            scheduledAt: isScheduled ? selectedDateTime?.toISOString() : null,
            communityId: communityId,
          });
          if (isDraft) {
            window.toast({
              description: 'Post saved as draft!',
              status: 'success',
              position: 'top',
              isClosable: true,
              duration: 5000,
            });
          }
        }

        if (!isDraft && isScheduled) {
          window.toast({
            description: 'Post scheduled successfully!',
            status: 'success',
            position: 'top',
            isClosable: true,
            duration: 5000,
          });
        }
        return;
      }

      // Publish post
      await PostRepository.createPost({
        data: {
          text: postText,
        },
        attachments: [],
        targetType: 'community',
        targetId: communityId,
        tags: ['admin'],
        metadata: {postImages, showImagesOnTop},
      });

      // Delete system post if it exists after publishing
      if (getPostDetailsQuery.data) {
        try {
          await AxiosInstance.delete(`/admin/communities/posts/${postId}`);
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }

      window.toast({
        description: 'Post published successfully!',
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
    onSuccess: () => {
      const communityId = getPostDetailsQuery.data?.communityId ?? community?.communityId;
      navigate(`/communities/${communityId}`);
    },
    onError: () => {
      window.toast({
        description: 'Something went wrong. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  if (loadingCommunity || getPostDetailsQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (id && !community) {
    return <div>Community Not found</div>;
  }

  if (postId && getPostDetailsQuery.isError) {
    return <div>Post Not found</div>;
  }

  return (
    <Box key={id}>
      <HStack justifyContent="space-between">
        <Box>
          <Text fontSize="16px" lineHeight="24px" fontWeight="400" color="#8E959E" mb={1}>
            Community
          </Text>
          <Text fontSize="36px" lineHeight="42px" fontWeight="700" color="#000000">
            {getPostDetailsQuery.data ? 'Edit Post' : 'New Post'}
          </Text>
        </Box>

        <HStack>
          <Button
            size="md"
            variant="outline"
            background="white"
            onClick={() => createOrUpdatePostMutation.mutate(true)}
            isDisabled={
              (!postText && postImages.length === 0) || createOrUpdatePostMutation.isPending
            }
            isLoading={createOrUpdatePostMutation.isPending}>
            Save as draft
          </Button>
          <Button
            colorScheme="black"
            size="md"
            isDisabled={
              (!postText && postImages.length === 0) || createOrUpdatePostMutation.isPending
            }
            onClick={() => createOrUpdatePostMutation.mutate(false)}
            isLoading={createOrUpdatePostMutation.isPending}>
            Publish
          </Button>
        </HStack>
      </HStack>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} mt={8}>
        <GridItem colSpan={8}>
          <PostInput
            postText={postText}
            postImageUrls={postImages}
            loading={createOrUpdatePostMutation.isPending}
            onChangePostText={text => setPostText(text)}
            onImageUpload={handleImageUpload}
            onRemovePostImage={removePostImage}
          />
        </GridItem>
        <GridItem colSpan={4}>
          <Box borderRadius="12px" padding="24px" bg="#FFFFFF" border="1px solid #E2E8F0">
            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel htmlFor="image-placement-switch" mb={6}>
                Show images on top of post
              </FormLabel>
              <Switch
                id="image-placement-switch"
                colorScheme="black"
                isChecked={showImagesOnTop}
                onChange={e => setShowImagesOnTop(e.target.checked)}
              />
            </FormControl>
            <Text fontSize="16px" lineHeight="18px" fontWeight="700" color="#1B1A1A" mb={2}>
              Schedule post
            </Text>
            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel htmlFor="schedule-post" mb="0">
                Schedule post
              </FormLabel>
              <Switch
                id="schedule-post"
                colorScheme="black"
                isChecked={isScheduled}
                onChange={e => setIsScheduled(e.target.checked)}
              />
            </FormControl>

            {isScheduled && (
              <Box mt={6}>
                <ChakraDateTimePicker
                  selectedDateTime={selectedDateTime}
                  onDateTimeChange={setSelectedDateTime}
                />
                <Text fontSize="14px" mt={6}>
                  Scheduled date in PST:{' '}
                  {selectedDateTime &&
                    format(
                      selectedDateTime.toLocaleString('en-US', {
                        timeZone: 'America/Los_Angeles',
                      }),
                      'MM/dd/yyyy, h.mmaaaa',
                    )}
                </Text>
              </Box>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
