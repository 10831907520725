import {useEffect, useState} from 'react';
import {EnvConfig} from '../base/EnvConfig';
import {HelmetProvider} from 'react-helmet-async';
import {ChakraProvider, createStandaloneToast} from '@chakra-ui/react';
import {theme} from './theme/Theme';
import {AppRoutes} from './router/Router';
import {useGlobalStore} from '../store/globalStore';
import {jwtDecode} from 'jwt-decode';
import {Session} from '@supabase/supabase-js';
import {AxiosInstance} from '../api/_AxiosInstance';
import supabaseClient from '../api/supabaseClient';
import {QueryClientProvider} from '@tanstack/react-query';
import queryClient from '../api/queryClient';
import {initializeAmity} from '../api/amitySdk';
import {User} from '../types/User';
import {FullPageLoader} from '../components/FullPageLoader';

const {ToastContainer, toast} = createStandaloneToast(theme);

// Add the standalone toast to window so we can use it outside of react components
declare global {
  interface Window {
    toast: typeof toast;
  }
}
window.toast = toast;

const App = (): JSX.Element => {
  const [loading, setLoading] = useState(true);

  async function createUserIfNeeded(supabaseAuthSession: Session | null) {
    if (supabaseAuthSession) {
      const response = await fetch(EnvConfig.BACKEND_BASE_URL + '/users', {
        method: 'POST',
        headers: {
          // This is the token that we get from Supabase.
          // Authorization: jwt,
          Authorization: 'Bearer ' + supabaseAuthSession.access_token,
        },
      });
      const data = (await response.json()) as User;
      await initializeAmity(data);
    }
  }

  // Fetch the supabase session as soon as the app loads & set it in global store
  useEffect(() => {
    async function initialize() {
      const {
        data: {session: firstSession},
      } = await supabaseClient.auth.getSession();

      useGlobalStore.setState({supabaseAuthSession: firstSession});

      supabaseClient.auth.onAuthStateChange(async (event, session) => {
        useGlobalStore.setState({supabaseAuthSession: session});

        if (event === 'SIGNED_IN' && session) {
          // Grab the user's role from the JWT token & log them out if they're not an admin
          const jwt = jwtDecode(session.access_token);

          const user_role = (jwt as {user_role: string}).user_role;

          if (user_role !== 'admin') {
            await supabaseClient.auth.signOut();
            window.toast({
              title: 'Error',
              description: 'You are not authorized to access this page.',
              status: 'error',
            });
            return;
          }

          AxiosInstance.defaults.headers.common.Authorization = `Bearer ${session.access_token}`;
          await createUserIfNeeded(session);
        } else if (event === 'TOKEN_REFRESHED' && session) {
          AxiosInstance.defaults.headers.common.Authorization = `Bearer ${session.access_token}`;
        }
      });
      if (firstSession) {
        await initializeAmity();
      }
      setLoading(false);
    }

    initialize();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider
        theme={theme}
        toastOptions={{
          defaultOptions: {
            position: 'top',
            isClosable: true,
            duration: 4000,
          },
        }}>
        <HelmetProvider>
          {loading ? (
            <FullPageLoader />
          ) : (
            <>
              <ToastContainer />
              <AppRoutes />
            </>
          )}
        </HelmetProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default App;
