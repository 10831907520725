/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import {
  HStack,
  Flex,
  Text,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerBody,
  DrawerFooter,
  Spinner,
  Button,
} from '@chakra-ui/react';
import NotificationHeader from './NotificationHeader';
import {
  Notification,
  CommentPreviewType,
  NotificationType,
} from '../../types/Notifications';
import {useMutation} from '@tanstack/react-query';
import SingleCommentThread from './SingleCommentThread';
import {CommentRepository, ReactionRepository} from '@amityco/ts-sdk';
import NotificationSendCommentInput from './NotificationSendCommentInput';
import {AmitySDKcommentType} from '../../types/AmityGetCommentByIdResponse';
import {amityApi} from '../../api/amityApi/amityApi';
import {AmitySdkPost} from '../../types/amity/AmitySdkPost';
import {useUpdateEffect} from 'react-use';

let nextPageFn: (() => void) | undefined;

export interface NotificationsModalProps {
  data: Notification;
  isOpen: boolean;
  onClose: () => void;
}

const NotificationsModal: React.FC<NotificationsModalProps> = ({data, isOpen, onClose}) => {
  const [loadingAllComments, setLoadingAllComments] = React.useState(false);
  const [loadingSingleThread, setLoadingSingleThread] = React.useState(false);
  const [singleThreadData, setSingleThreadData] =
    React.useState<AmitySDKcommentType | null>(null);

  const [allComments, setAllComments] = React.useState<AmitySDKcommentType[]>([]);
  const [commentsError, setCommentsError] = React.useState<any>(null);
  const [isCommentThread, setIsCommentThread] = React.useState<boolean | null>(null);

  const [amityPost, setAmityPost] = React.useState<AmitySdkPost | null>(null);
  const [loadingAmityPost, setLoadingAmityPost] = React.useState(false);
  const [amityPostError, setAmityPostError] = React.useState<any>(null);

  const [commentText, setCommentText] = React.useState('');
  const [hasMore, setHasMore] = React.useState<boolean | undefined>(false);

  const addNewCommentMutation = useMutation({
    mutationFn: addNewComment,
    onError() {
      window.toast({
        description: 'Cannot create comment. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  async function addNewComment() {
    const postId = getPostId();

    const createdCommentResponse = (await CommentRepository.createComment({
      referenceType: 'post',
      referenceId: postId!,
      data: {
        text: commentText,
      },
      metadata: {
        admin: true,
      },
    })) as unknown as {data: AmitySDKcommentType};
    setAllComments(prev => [...prev, createdCommentResponse.data]);

    window.toast({
      description: 'Reply created successfully.',
      status: 'success',
      position: 'top-right',
      isClosable: true,
      duration: 5000,
    });

    setCommentText('');

    return createdCommentResponse;
  }

  const likePostMutation = useMutation({
    mutationFn: async () => {
      await ReactionRepository.addReaction('post', data.amityPostId!, 'like');
    },
    onError(e) {
      handleUnlikePost();
    },
  });

  const unlikePostMutation = useMutation({
    mutationFn: async () => {
      await ReactionRepository.removeReaction('post', data.amityPostId!, 'like');
    },
    onError(e) {
      handleLikePost();
    },
  });

  function handleLikeButtonClick() {
    if (amityPost!.myReactions?.includes('like')) {
      handleUnlikePost();
    } else {
      handleLikePost();
    }
  }

  function handleLikePost() {
    setAmityPost(prev => {
      if (!prev) return prev;

      return {
        ...prev,

        reactions: {
          ...prev.reactions,
          like: (prev.reactions.like ?? 0) + 1,
        },
        myReactions: [...(prev.myReactions ?? []), 'like'],
      };
    });

    likePostMutation.mutate();
  }

  function handleUnlikePost() {
    setAmityPost(prev => {
      if (!prev) return prev;

      return {
        ...prev,

        reactions: {
          ...prev.reactions,
          like: prev.reactions.like - 1,
        },
        myReactions: prev.myReactions?.filter(r => r !== 'like'),
      };
    });

    unlikePostMutation.mutate();
  }

  function updateComment(amityComment: AmitySDKcommentType) {
    setAllComments(prev => {
      const index = prev.findIndex(comment => comment._id === amityComment._id);
      if (index === -1) return prev;
      prev[index] = amityComment;
      return [...prev];
    });
  }

  function getPostId() {
    if (data) {
      if (data.amityPostId) {
        return data.amityPostId;
      }
      if (data.notificationType === NotificationType.STEP_COMMENT) {
        return data.commentPreviewType === CommentPreviewType.COMMENT
          ? data.step!.amityCommentsPostId
          : data.step!.amityQuestionsPostId;
      }
    }
    return null;
  }

  // used to get all comments
  useUpdateEffect(() => {
    if (!isOpen) return;

    const postId = getPostId();

    const commentsPostParams: Amity.CommentLiveCollection = {
      referenceType: 'post',
      referenceId: postId!,
      dataTypes: {
        values: ['text'],
        matchType: 'exact',
      },
      limit: 10,
    };

    let unsubscribe: Amity.Unsubscriber;

    if (isCommentThread) {
      setLoadingSingleThread(true);
      amityApi
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        .get(`/v3/comments/${data.amityParentCommentId || data.amityCommentId}`)
        .then(({data}) => {
          const mappedComment: AmitySDKcommentType = {
            ...data.comments?.[0],
            creator: data.users?.find(
              (user: any) => user.userId === data.comments[0].userId,
            ),
          };
          setSingleThreadData(mappedComment);
        })
        .catch(error => {
          if (error.response?.status === 404) {
            setCommentsError('Comment not found.');
          } else {
            setCommentsError('There was an error getting the comments.');
          }
        })
        .finally(() => {
          setLoadingSingleThread(false);
        });
    } else {
      unsubscribe = CommentRepository.getComments(
        commentsPostParams,
        ({data: comments, onNextPage, hasNextPage, loading, error}) => {
          if (error) {
            setLoadingAllComments(false);
            setCommentsError('There was an error getting the comments.');

            return;
          }
          if (loading) {
            setLoadingAllComments(true);
            return;
          }
          if (comments) {
            setLoadingAllComments(false);
            setAllComments(comments as unknown as AmitySDKcommentType[]);
            setCommentsError(null);
          }
          setHasMore(hasNextPage);
          nextPageFn = onNextPage;
        },
      );
    }

    if (
      [NotificationType.COMMUNITY_COMMENT, NotificationType.COMMUNITY_POST].includes(
        data.notificationType,
      )
    ) {
      setLoadingAmityPost(true);
      amityApi
        .get(`/v3/posts/${postId}?type=public`)
        .then(({data}) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setAmityPost({
            ...data.posts[0],
            creator: data.users.find(
              (user: any) => user.userInternalId === data.posts[0].postedUserInternalId,
            ),
          });
          setAmityPostError(null);
        })
        .catch(error => {
          setAmityPostError('There was an error getting the post.');
        })
        .finally(() => {
          setLoadingAmityPost(false);
        });
    }

    return () => {
      unsubscribe?.();
    };
  }, [isCommentThread]);

  useUpdateEffect(() => {
    if (isOpen) {
      setIsCommentThread(
        [NotificationType.COMMUNITY_COMMENT, NotificationType.STEP_COMMENT].includes(
          data.notificationType,
        ),
      );
    }
    return () => {
      setAllComments([]);
      setCommentsError(null);
      setLoadingAllComments(false);
      setIsCommentThread(null);
      setCommentText('');
      setHasMore(false);
      setSingleThreadData(null);
      setAmityPost(null);
      setLoadingAmityPost(false);
      setAmityPostError(null);
    };
  }, [isOpen]);

  function deleteComment(commentId: string) {
    setAllComments(prev => prev.filter(comment => comment._id !== commentId));
    if (commentId === singleThreadData?._id) {
      setSingleThreadData(null);
    }
  }

  function renderDrawerBody() {
    if (
      loadingSingleThread ||
      (loadingAllComments && !allComments?.length) ||
      loadingAmityPost
    ) {
      return (
        <Flex justifyContent="center" alignItems="center" height="100%">
          <HStack>
            <Text>Loading comments...</Text>
            <Spinner color="blue.500" />
          </HStack>
        </Flex>
      );
    }

    if (commentsError) {
      return (
        <Flex justifyContent="center" alignItems="center" height="100%">
          <HStack>
            <Text>{commentsError}</Text>
          </HStack>
        </Flex>
      );
    }

    if (
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      (isCommentThread && !singleThreadData) ||
      (!isCommentThread && !allComments?.length)
    ) {
      return (
        <Flex justifyContent="center" alignItems="center" height="100%">
          <HStack>
            <Text>There are no comments to display</Text>
          </HStack>
        </Flex>
      );
    }

    /* single comment thread */
    if (isCommentThread && singleThreadData) {
      return (
        <SingleCommentThread
          defaultIsReplying
          highlightCommentId={data.amityCommentId}
          parentComment={singleThreadData}
          commentThreadId={data.amityCommentId}
          handleCommentDelete={() => setSingleThreadData(null)}
          updateParentComment={setSingleThreadData}
        />
      );
    }

    if (!isCommentThread && allComments?.length > 0) {
      return (
        <>
          {allComments.map(comment => (
            <SingleCommentThread
              key={comment._id}
              highlightCommentId={data.amityCommentId}
              parentComment={comment}
              handleCommentDelete={() => deleteComment(comment._id)}
              updateParentComment={updateComment}
            />
          ))}
          {hasMore && (
            <Flex justifyContent="center" alignItems="center">
              <Button onClick={nextPageFn} isLoading={loadingAllComments}>
                Load more
              </Button>
            </Flex>
          )}
        </>
      );
    }
    return null;
  }

  if (!open || !data) return null;

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right">
      <DrawerOverlay />

      <DrawerContent
        marginY="0"
        minWidth={{
          base: '80%',
          md: '715px',
        }}
        maxH="100%"
        borderLeftRadius="24px"
        borderRightRadius={0}>
        <NotificationHeader
          programName={data?.program?.title}
          dayName={data?.day?.title}
          stepName={data?.step?.textStepTitle}
          isCommentThread={!!isCommentThread}
          setIsCommentThread={() => setIsCommentThread(prev => !prev)}
          loading={loadingAllComments}
          commentType={data.commentPreviewType}
          notificationType={data.notificationType}
          thumbnailUrl={
            data.step?.type === 'video'
              ? data.step?.video?.thumbnailUrl
              : data.step?.textStepThumbnails?.thumbnail ?? data.step?.textStepThumbnailUrl
          }
          amityPost={amityPost}
          onPostLikeClick={handleLikeButtonClick}
        />
        <DrawerBody>{renderDrawerBody()}</DrawerBody>

        {((!isCommentThread && allComments?.length > 0) || !!amityPost) && (
          <DrawerFooter>
            <HStack w="full" borderTopWidth="1px" paddingTop={6} paddingBottom={4}>
              <NotificationSendCommentInput
                comment={commentText}
                setComment={setCommentText}
                loadingCreatingReply={addNewCommentMutation.isPending}
                handlePostClick={addNewCommentMutation.mutate}
              />
            </HStack>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default NotificationsModal;
