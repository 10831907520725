import {Auth} from '@supabase/auth-ui-react';
import {ThemeSupa} from '@supabase/auth-ui-shared';
import {
  Card,
  CardBody,
  Center,
  Container,
  Flex,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/react';
import {Link} from 'react-router-dom';

import {AUTH_PAGES} from '../../../base/router/pages';

import {useGlobalStore} from '../../../store/globalStore';
import supabaseClient from '../../../api/supabaseClient';

const LoginPage = (): JSX.Element => {
  const state = useGlobalStore(state => state);

  if (!state.supabaseAuthSession) {
    return (
      <Container minH="100%">
        <Flex justifyContent="center" alignItems="center" width="100%" minH="100vh">
          <Card width={480}>
            <CardBody>
              <Text fontSize="md" align="center" mt={2} color="gray">
                Sign in to your account.
              </Text>
              <Auth
                supabaseClient={supabaseClient}
                appearance={{theme: ThemeSupa}}
                providers={[]}
                showLinks={false}
              />
              <Center flexWrap="wrap">
                <Text fontSize="sm" color="gray.500" me={1}>
                  Forgot your password?
                </Text>
                <ChakraLink
                  as={Link}
                  to={AUTH_PAGES.forgotPassword}
                  color="gray.600"
                  fontWeight="medium"
                  fontSize="sm"
                  _hover={{color: 'gray.900'}}>
                  Reset Password
                </ChakraLink>
              </Center>
            </CardBody>
          </Card>
        </Flex>
      </Container>
    );
  }

  return <></>;
};

export default LoginPage;
