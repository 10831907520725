import React, {useEffect} from 'react';
import {
  Box,
  Button,
  Image,
  CloseButton,
  VStack,
  HStack,
  useDisclosure,
  Text,
  Flex,
} from '@chakra-ui/react';
import {v4 as uuidv4} from 'uuid';
import {FaImage} from 'react-icons/fa';
import supabaseClient from '../../api/supabaseClient';
import UploadImageModal from '../../components/UploadImageModal/UploadImageModal';

import {
  MDXEditor,
  MDXEditorMethods,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  CreateLink,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';

interface ImagePreviewProps {
  image: string;
  onRemove: () => void;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({image, onRemove}) => {
  return (
    <Box position="relative" width="fit-content">
      <Image
        src={image}
        alt="Uploaded"
        borderRadius="md"
        maxHeight="400px"
        fallback={
          <Flex
            justify="center"
            align="center"
            borderRadius="md"
            width="100px"
            height="100px"
            bg="gray.100">
            <Text textAlign="center">Loading Image</Text>
          </Flex>
        }
      />
      <CloseButton
        position="absolute"
        top="2"
        right="2"
        onClick={onRemove}
        colorScheme="red"
        zIndex={2}
      />
    </Box>
  );
};

export interface PostInputProps {
  isEditing?: boolean;
  postText: string;
  postImageUrls: string[];
  loading?: boolean;
  onChangePostText?: (text: string) => void;
  onImageUpload: (url: string) => void;
  onRemovePostImage: (url: string) => void;
  onPostClick?: ({
    postText,
    postImageUrls,
  }: {
    postText: string;
    postImageUrls: string[];
  }) => void;
}

const PostInput: React.FC<PostInputProps> = props => {
  const {
    isEditing,
    postText = '',
    postImageUrls = [],
    loading = false,
    onImageUpload,
    onChangePostText,
    onRemovePostImage,
    onPostClick,
  } = props;
  const mdxEditorRef = React.useRef<MDXEditorMethods>(null);

  const {isOpen, onClose, onOpen} = useDisclosure();

  useEffect(() => {
    mdxEditorRef.current?.setMarkdown(postText);
  }, [postText]);

  function handleCreatePostClick() {
    onPostClick?.({
      postText,
      postImageUrls,
    });
  }

  const handleImageUpload = async (
    blobs: {
      blob: Blob;
      name: string;
    }[],
  ) => {
    const blob = blobs.find(blob => blob.name === 'original')?.blob;

    if (!blob) {
      return;
    }
    const uniqueId = uuidv4();
    await supabaseClient.storage.from('markdownImages').upload(`${uniqueId}`, blob, {
      cacheControl: '3600',
      upsert: false,
    });
    const {data: pathData} = supabaseClient.storage
      .from('markdownImages')
      .getPublicUrl(`${uniqueId}`);

    onImageUpload(pathData.publicUrl);
  };

  return (
    <VStack
      borderRadius="24px"
      padding="16px 16px 12px 16px"
      bg="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxShadow="0 0 10px rgba(0, 0, 0, 0.1)" // Adding some shadow for better visualization
    >
      <UploadImageModal
        isOpen={isOpen}
        onClose={onClose}
        headerText="Upload Image"
        onImageUpload={handleImageUpload}
        aspect={1}
        sizes={[]}
        uploadOriginalSize
      />

      <HStack alignItems="flex-start" spacing={3} width="full">
        <VStack align="stretch" spacing={3} flex="1">
          <MDXEditor
            ref={mdxEditorRef}
            markdown={postText}
            placeholder="What's going on..."
            plugins={[
              linkPlugin(),
              linkDialogPlugin(),
              headingsPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <UndoRedo />
                    <BoldItalicUnderlineToggles options={['Bold', 'Underline']} />
                    <CreateLink />
                  </>
                ),
              }),
            ]}
            onChange={(markdown: string) => {
              onChangePostText?.(markdown);
            }}
          />

          <HStack spacing={3}>
            {postImageUrls.map(url => (
              <ImagePreview key={url} image={url} onRemove={() => onRemovePostImage(url)} />
            ))}
          </HStack>

          <HStack justifyContent="space-between" width="full">
            <Button leftIcon={<FaImage />} variant="outline" size="sm" onClick={onOpen}>
              Add media
            </Button>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default PostInput;
