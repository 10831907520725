import {Table, Thead, Tbody, Tr, Th, Td, chakra, TableContainer} from '@chakra-ui/react';
import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  OnChangeFn,
  ColumnDef,
  RowSelectionState,
} from '@tanstack/react-table';

import {Program} from '../../types/Program';
import {Video} from '../../types/Video';

interface ColumnMetadata {
  isNumeric?: boolean;
}

const ResultsTable = ({
  columns,
  data,
  onSortChange,
  sorting,
  rowSelection = undefined,
  onRowSelectionChange = undefined,
  onRowClick,
  manualSorting = false,
}: {
  columns: ColumnDef<any>[];
  data: Program[] | Video[] | Notification[];
  onSortChange?: OnChangeFn<SortingState>;
  sorting: SortingState;
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  onRowClick?: (row: any) => void;
  manualSorting?: boolean;
}) => {
  const table = useReactTable({
    columns,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: onSortChange,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      rowSelection,
    },
    manualSorting,
    onRowSelectionChange,
  });

  return (
    <TableContainer>
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const meta: ColumnMetadata | undefined = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}>
                    {flexRender(header.column.columnDef.header, header.getContext())}

                    <chakra.span pl="4">
                      {(() => {
                        if (header.column.getIsSorted()) {
                          if (header.column.getIsSorted() === 'desc') {
                            return <TriangleDownIcon aria-label="sorted descending" />;
                          } else {
                            return <TriangleUpIcon aria-label="sorted ascending" />;
                          }
                        } else {
                          return null;
                        }
                      })()}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map(row => (
            <Tr
              key={row.id}
              cursor={onRowClick && 'pointer'}
              onClick={() => onRowClick && onRowClick(row.original)}>
              {row.getVisibleCells().map(cell => {
                const meta: ColumnMetadata | undefined = cell.column.columnDef.meta;
                return (
                  <Td key={cell.id} isNumeric={meta?.isNumeric}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ResultsTable;
