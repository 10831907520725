import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';

export interface ConfirmPopupProps {
  onDelete: () => void;
  onCancel: () => void;
  cancelRef: React.LegacyRef<HTMLButtonElement>;
  isOpen: boolean;
  loading: boolean;
  headerText: string;
  dialogDescriptionText: string;
  confirmButtonText?: string;
  confirmButtonColor: string;
}

export default function ConfirmPopup(props: ConfirmPopupProps) {
  const {
    onDelete,
    onCancel,
    headerText,
    cancelRef,
    isOpen,
    loading,
    dialogDescriptionText,
    confirmButtonColor,
    confirmButtonText,
  } = props;

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef as any} onClose={onCancel}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {headerText}
          </AlertDialogHeader>

          <AlertDialogBody>{dialogDescriptionText}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCancel} isDisabled={loading}>
              Cancel
            </Button>
            <Button
              colorScheme={confirmButtonColor}
              onClick={onDelete}
              ml={3}
              isLoading={loading}>
              {confirmButtonText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
