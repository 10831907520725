import axios, {AxiosError} from 'axios';
import {EnvConfig} from '../base/EnvConfig';
import supabaseClient from './supabaseClient';

// Base Axios Instance
export const AxiosInstance = axios.create({
  baseURL: EnvConfig.BACKEND_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

let _authorizing: Promise<void> | null = null;

// Error handler
AxiosInstance.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    const statusCode = error.response?.status;

    if (statusCode && statusCode === 401) {
      // create pending authorization
      _authorizing ??= supabaseClient.auth
        .refreshSession()
        .then(authResponse => {
          AxiosInstance.defaults.headers.common.Authorization = `Bearer ${authResponse.data?.session?.access_token}`;
        })
        .catch(() => Promise.reject(new Error('Could not refresh the jwt token')))
        .finally(() => (_authorizing = null));

      const originalRequestConfig = error.config;
      delete originalRequestConfig?.headers.Authorization;

      return _authorizing.then(() => AxiosInstance.request(error.config!));
    }

    if (statusCode && statusCode === 403) {
      // handle 403 error
    }

    interface ErrorResponse {
      message?: string;
      // add other properties if needed
    }

    // Then use it like this:
    const errorMessage: string =
      (error.response?.data as ErrorResponse)?.message ??
      error.message ??
      'Something went wrong. Please try again later!';

    window.toast({
      description: errorMessage,
      status: 'error',
      position: 'top',
      isClosable: true,
      duration: 5000,
    });

    return Promise.reject(new Error(errorMessage));
  },
);
