import {Crop} from 'react-image-crop';
import supabaseClient from '../api/supabaseClient';
import {AxiosInstance} from '../api/_AxiosInstance';

export interface UploadUserProfilePictureOptions {
  crop: Crop;
  file: File;
  supabaseUserId: string;
  oldPictureLocation?: string | null;
}

export async function uploadUserProfilePicture(options: UploadUserProfilePictureOptions) {
  const {file, supabaseUserId, crop, oldPictureLocation} = options;

  if (!file) return;

  const croppedImageFile = await getCroppedImage({
    crop: crop,
    file: file,
  });

  if (!croppedImageFile) {
    return null;
  }
  const fileNameCropped = `${supabaseUserId}-profile-picture-cropped-${Date.now()}.jpeg`;

  const {data: croppedFileData, error: croppedFileError} = await supabaseClient.storage
    .from('avatars')
    .upload(`${supabaseUserId}/${fileNameCropped}`, croppedImageFile);

  if (croppedFileError) {
    console.error('Error uploading file:', croppedFileError);
    return null;
  }

  if (oldPictureLocation) {
    await supabaseClient.storage.from('avatars').remove([oldPictureLocation]);
  }

  const {
    data: {publicUrl: publicCroppedFileUrl},
  } = supabaseClient.storage.from('avatars').getPublicUrl(croppedFileData.path);

  await AxiosInstance.post('/users/set-profile-picture-url', {
    profilePictureUrl: publicCroppedFileUrl,
    supabaseProfilePictureLocation: croppedFileData.path,
  });

  return {
    publicCroppedFileUrl,
    croppedFileData,
  };
}

export interface GetCroppedImageOptions {
  crop: Crop;
  file: File;
}

export function getCroppedImage(options: GetCroppedImageOptions): Promise<File | null> {
  const {crop, file} = options;

  return new Promise((res, rej) => {
    if (!crop || !file) {
      return res(null);
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result as string;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          return;
        }

        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height,
        );

        canvas.toBlob(blob => {
          if (blob) {
            const croppedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            return res(croppedFile);
          }
          return res(null);
        }, 'image/jpeg');
      };
    };
  });
}
