import {
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link as ChakraLink,
  Stack,
  InputRightElement,
  InputGroup,
  IconButton,
  Text,
  Center,
  useToast,
} from '@chakra-ui/react';
import {useState} from 'react';
import {BsEye, BsEyeSlash} from 'react-icons/bs';
import {Link} from 'react-router-dom';

import {AUTH_PAGES, PROTECTED_PAGES} from '../../../base/router/pages';
import supabaseClient from '../../../api/supabaseClient';

const ChangePasswordPage = (): JSX.Element => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const changePassword = () => {
    setIsLoading(true);
    supabaseClient.auth.updateUser({password: password}).then(res => {
      if (res.error) {
        toast({
          description: res.error.message || 'An error occurred. Please try again.',
          status: 'error',
          title: 'Error',
        });
      } else {
        setPasswordUpdated(true);
      }
      setIsLoading(false);
    });
  };

  return (
    <Container minH="100%">
      <Flex justifyContent="center" alignItems="center" width="100%" minH="100vh">
        <Card width={480}>
          <CardBody>
            <Text fontSize="md" align="center" mt={2} color="gray">
              {passwordUpdated ? 'Password updated.' : 'Update your password.'}
            </Text>

            <form>
              <Stack spacing={4} mt={8}>
                <FormControl variant="floating" hidden={passwordUpdated}>
                  <InputGroup>
                    <Input
                      placeholder="" // leave empty for floating label
                      aria-label="Password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="password"
                      required
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <FormLabel>Password</FormLabel>
                    <InputRightElement>
                      <IconButton
                        aria-label="Show password"
                        size="sm"
                        icon={!showPassword ? <BsEye /> : <BsEyeSlash />}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <Button
                  my={4}
                  isDisabled={!password}
                  onClick={changePassword}
                  hidden={passwordUpdated}
                  colorScheme="green"
                  isLoading={isLoading}>
                  Update Password
                </Button>
                <Center flexWrap="wrap">
                  <Text fontSize="sm" color="gray.500" me={1}>
                    {passwordUpdated
                      ? 'Password updated successfully.'
                      : 'Remembered your password?'}
                  </Text>
                  <ChakraLink
                    as={Link}
                    to={passwordUpdated ? PROTECTED_PAGES.programs : AUTH_PAGES.login}
                    color="gray.600"
                    fontWeight="medium"
                    fontSize="sm"
                    _hover={{color: 'gray.900'}}>
                    {passwordUpdated ? 'Go to Dashboard' : 'Login'}
                  </ChakraLink>
                </Center>
              </Stack>
            </form>
          </CardBody>
        </Card>
      </Flex>
    </Container>
  );
};

export default ChangePasswordPage;
