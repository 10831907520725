import {AUTH_PAGES, PROTECTED_PAGES} from '../router/pages';
import LoginPage from '../../pages/_AuthPages/LoginPage/LoginPage';
import DashboardPage from '../../pages/DashboardPage/DashboardPage';
import ForgotPasswordPage from '../../pages/_AuthPages/ForgotPasswordPage/ForgotPasswordPage';
import ChangePasswordPage from '../../pages/_AuthPages/ForgotPasswordPage/ChangePasswordPage';
import ProfilePage from '../../pages/ProfilePage/ProfilePage.tsx';
import VideosPage from '../../pages/VideosPages/VideosPage.tsx';
import EditVideo from '../../pages/VideosPages/EditVideo/EditVideoPage.tsx';
import ProgramDetailsPage from '../../pages/ProgramPages/ProgramDetailsPage.tsx';
import ProgramsPage from '../../pages/ProgramPages/ProgramsPage.tsx';

import {v4 as uuidv4} from 'uuid';
import NotificationsPage from '../../pages/NotificationsPage/NotificationsPage.tsx';
import UsersPage from '../../pages/UsersPage/UsersPage.tsx';
import CommunitiesPage from '../../pages/CommunitiesPage/CommunitiesPage.tsx';
import CommunityPostPage from '../../pages/CommunitiesPage/CommunityPostPage.tsx';

export interface RouteType {
  id: string;
  path: string;
  title?: string;
  component: any;
  type: 'public' | 'auth' | 'protected' | 'pw-reset';
}

export const APP_ROUTES: RouteType[] = [
  {
    id: uuidv4(),
    path: AUTH_PAGES.login,
    title: 'Login',
    component: LoginPage,
    type: 'auth',
  },
  {
    id: uuidv4(),
    path: AUTH_PAGES.forgotPassword,
    title: 'Forgot Password',
    component: ForgotPasswordPage,
    type: 'auth',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.changePassword,
    title: 'Change Password',
    component: ChangePasswordPage,
    type: 'pw-reset', // For now, not setting as "auth" or "protected" as it needs to be handled as a special case for the forgot password flow
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.dashboard,
    title: 'Dashboard',
    component: DashboardPage,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.index,
    title: 'Programs',
    component: ProgramsPage,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.profile,
    title: 'Profile',
    component: ProfilePage,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.videos,
    title: 'Videos',
    component: VideosPage,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.editVideo,
    title: 'Edit video',
    component: EditVideo,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.newProgram,
    title: 'New Program',
    component: ProgramDetailsPage,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.programs,
    title: 'Programs',
    component: ProgramsPage,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.editProgram,
    title: 'Edit Program',
    component: ProgramDetailsPage,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.notifications,
    title: 'Notifications',
    component: NotificationsPage,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.users,
    title: 'Users',
    component: UsersPage,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.communityDetails,
    title: 'Community Details',
    component: CommunitiesPage,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.createCommunityPost,
    title: 'Create Community Post',
    component: CommunityPostPage,
    type: 'protected',
  },
  {
    id: uuidv4(),
    path: PROTECTED_PAGES.editSystemPosts,
    title: 'Edit Post',
    component: CommunityPostPage,
    type: 'protected',
  },
];
