import {useDisclosure} from '@chakra-ui/react';
import {useRef} from 'react';
import ResultsTableEditMenuColumn from './ResultsTableEditMenuColumn';
import {useMutation} from '@tanstack/react-query';
import DeleteConfirmPopup from '../Common/DeleteConfirmPopup';

export interface ResultsTableDeleteMenuColumnProps {
  onDelete: () => Promise<any>;
}

const ResultsTableDeleteMenuColumn = (props: ResultsTableDeleteMenuColumnProps) => {
  const {onDelete} = props;
  const cancelRef = useRef(null);

  const {isOpen, onOpen, onClose} = useDisclosure();

  const mutation = useMutation({
    mutationFn: onDelete,
    onSuccess() {
      onClose();
    },
    onError() {
      window.toast({
        description: 'Something went wrong. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return (
    <div>
      <DeleteConfirmPopup
        onDelete={mutation.mutate}
        onCancel={onClose}
        cancelRef={cancelRef}
        isOpen={isOpen}
        loading={mutation.isPending}
        headerText="Delete Notification"
      />
      <ResultsTableEditMenuColumn onDelete={onOpen} />
    </div>
  );
};
export default ResultsTableDeleteMenuColumn;
