import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import ErrorWrapperPage from '../../pages/_ErrorWrapperPage/ErrorWrapperPage';

import {APP_ROUTES} from './routes';
import {RouteComponent} from './RouteComponent';

export const AppRoutes = (): JSX.Element => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route ErrorBoundary={ErrorWrapperPage}>
        {APP_ROUTES.map(route => (
          <Route
            key={route.id}
            path={route.path}
            element={<RouteComponent {...route} key={route.id} />}
          />
        ))}
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};
